import React, { useState } from 'react';
import {
  Box,
  Tab,
  Tabs,
  Grid,
  Typography,
  Avatar,
  Fade,
  keyframes,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { SERVICES } from '../../constants/services';
import SectionHeader from '../SectionHeader/section-header';
import { IoCheckmarkCircleSharp } from 'react-icons/io5';
import AnimateButton from '../Button/AnimateButton';

const TabPanel = ({ children, value, index }) => {
  return (
    <Fade in={value === index} timeout={700}>
      <div role="tabpanel" hidden={value !== index} style={{ width: '100%' }}>
        {value === index && <Box sx={{ py: 3 }}>{children}</Box>}
      </div>
    </Fade>
  );
};

const Ribbon = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '50px',
  right: '-80px',
  width: '170px',
  height: '100px',
  backgroundColor: '#F26E21',
  color: 'white',
  transform: 'rotate(45deg)',
  transformOrigin: 'top right',
  zIndex: 10,
  boxShadow: theme.shadows[4],
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  paddingRight: theme.spacing(2),
}));

const RibbonText = styled(Typography)(() => ({
  width: '100%',
  textAlign: 'center',
  fontSize: '0.75rem',
  lineHeight: 'normal',
  textTransform: 'uppercase',
  color: 'white',
  letterSpacing: '1px',
  fontWeight: 'bold',
  marginTop: '60px',
}));

const borderAnimation = keyframes`
      from {
        width: 0;
      }
      to {
        width: 100%;
      }
    `;

const Services = () => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box id="homepage-services-section">
      <SectionHeader title="Services" subtitle="Explore Our Services" />
      <Box
        sx={{
          borderRadius: 2,
          overflow: 'hidden',
          mt: 5,
          minWidth: '100%',
          mx: 0,
          boxShadow: '0 10px 45px rgba(97, 97, 97, 0.1)',
        }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          centered
          variant="fullWidth"
          TabIndicatorProps={{
            style: {
              display: 'none',
            },
          }}
        >
          {SERVICES.map((tab, index) => (
            <Tab
              key={index}
              id={tab.id}
              label={tab.label}
              icon={
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <Avatar
                    src={tab.icon}
                    alt={tab.label}
                    sx={{
                      width: { sm: 40, md: 70 },
                      height: { sm: 40, md: 70 },
                    }}
                  />
                </Box>
              }
              sx={{
                fontSize: { xs: '0.7rem', sm: '0.8rem', md: '1.2rem' },
                textTransform: 'capitalize',
                backgroundColor: 'white',
                color: 'black',
                fontFamily: "'Poppins', sans-serif",
                position: 'relative',
                overflow: 'hidden',
                '&.Mui-selected': {
                  color: '#000000',
                  borderBottom: '4px solid #F26E21',
                  borderBottomRightRadius: 3,
                  borderBottomLeftRadius: 3,
                },
                '&:hover:not(.Mui-selected):after': {
                  content: '""',
                  display: 'block',
                  height: '4px',
                  backgroundColor: '#F26E21',
                  animation: `${borderAnimation} 0.5s forwards`,
                  position: 'absolute',
                  bottom: '0px',
                  left: 0,
                  width: '100%',
                },
                '&::after': {
                  content: '""',
                  height: '4px',
                  backgroundColor: 'transparent',
                  width: '0',
                  position: 'absolute',
                  bottom: '-4px',
                  left: 0,
                },
              }}
            />
          ))}
        </Tabs>
      </Box>
      {SERVICES.map((tab, index) => (
        <TabPanel key={index} value={value} index={index}>
          <Box
            id={tab.id + '-panel'}
            sx={{
              position: 'relative',
              overflow: 'hidden',
              padding: 2,
              color: 'black',
              borderRadius: '10px',
            }}
          >
            {tab.details.comingSoon && (
              <Box sx={{ width: '100%', height: '100%', borderRadius: 1 }}>
                <Ribbon>
                  <RibbonText>COMING SOON</RibbonText>
                </Ribbon>
              </Box>
            )}
            <Box sx={{ height: '100%' }}>
              <Grid
                sx={{ height: '100%' }}
                container
                spacing={4}
                alignItems="start"
              >
                <Grid
                  sx={{ height: '100%', width: '600px' }}
                  item
                  xs={12}
                  md={6}
                >
                  <Box sx={{ maxWidth: '600px', paddingTop: '10px' }}>
                    <Typography
                      sx={{
                        fontSize: {
                          xs: '1.4rem',
                          sm: '1.6rem',
                          md: '1.8rem',
                        },
                        fontFamily: "'Poppins', sans-serif",
                      }}
                      variant="h5"
                      gutterBottom
                    >
                      {tab.details.title}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: '1rem',
                        fontFamily: "'Poppins', sans-serif",
                        color: '#313131',
                        fontWeight: 500,
                      }}
                      variant="body1"
                      paragraph
                      gutterBottom
                      mb={4}
                    >
                      {tab.details.description}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      flexGrow: 0,
                      paddingBottom: 5,
                      display: { sm: 'block', md: 'none' },
                    }}
                  >
                    <AnimateButton href="/#pricing">Subscribe</AnimateButton>
                  </Box>
                </Grid>
                <Grid
                  sx={{ position: 'relative', overflow: 'hidden' }}
                  item
                  xs={12}
                  md={6}
                >
                  <Box sx={{ padding: 0 }}>
                    <Box container>
                      {tab.details.advantages.map((advantage, idx) => (
                        <Box
                          sx={{
                            py: 2,
                            display: 'flex',
                            alignItems: 'flex-start',
                          }}
                          key={idx}
                        >
                          {/* Adjusting grid item to be half width on small screens and above */}
                          <Box
                            sx={{
                              minWidth: 30,
                            }}
                          >
                            <IoCheckmarkCircleSharp size={20} color="#F26E21" />
                          </Box>

                          <Typography
                            variant="h5"
                            sx={{
                              fontFamily: "'Poppins', sans-serif",
                              fontSize: '16px',
                              color: '#313131',
                              fontWeight: 500,
                            }}
                          >
                            {advantage.label}
                          </Typography>
                          {/* <ListItemText sx={{fontFamily: "'Poppins', sans-serif", fontSize:"14px"}} primary={advantage.label} /> */}
                        </Box>
                      ))}
                    </Box>
                  </Box>
                </Grid>
              </Grid>

              <Grid
                sx={{ height: '100%', marginTop: '10px' }}
                container
                spacing={4}
                alignItems="start"
              >
                <Grid
                  sx={{ position: 'relative', overflow: 'hidden' }}
                  item
                  xs={12}
                  md={6}
                >
                  <Box
                    sx={{
                      flexGrow: 0,
                      paddingBottom: 5,
                      display: { xs: 'none', sm: 'none', md: 'block' },
                    }}
                  >
                    <AnimateButton href="/#pricing">Subscribe</AnimateButton>
                  </Box>
                </Grid>
                <Grid
                  sx={{ position: 'relative', overflow: 'hidden' }}
                  item
                  xs={12}
                  md={6}
                >
                  <Box
                    sx={{
                      flexGrow: 1,

                      display: 'flex',
                      flexWrap: 'wrap',
                      justifyContent: 'space-between',
                      gap: 2, // For spacing between items
                    }}
                  >
                    {tab.details.statistics.map((statistic, idx) => (
                      <Box
                        key={idx}
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'center',
                            gap: 2,
                          }}
                        >
                          <Box>{statistic.icon}</Box>
                          <Box sx={{ maxWidth: '100%' }}>
                            <Typography
                              sx={{
                                fontFamily: "'Poppins', sans-serif",
                                fontWeight: 500,
                                fontSize: {
                                  xs: '2rem',
                                  sm: '2.2rem',
                                  md: '2.5rem',
                                },
                                marginBottom: 0,
                              }}
                              variant="h1"
                            >
                              {statistic.value + '+'}
                            </Typography>
                            <Typography
                              variant="h5"
                              sx={{
                                fontFamily: "'Poppins', sans-serif",
                                fontSize: '14px',
                                color: '#313131',
                                fontWeight: 500,
                              }}
                            >
                              {statistic.label}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    ))}
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </TabPanel>
      ))}
    </Box>
  );
};

export default Services;