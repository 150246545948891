import React from "react";
import useUpdateManufacturerURLs from "../../hooks/useUpdateManufacturerURLs";
import { Box, Button } from "@mui/material";


const UpdateManufacturerURLsForm = () => {

    const { updateURLs, statusMessage } = useUpdateManufacturerURLs();

    const handleSubmit = async (event) => {
        event.preventDefault();
        updateURLs();
    };

    return (
        <form onSubmit={handleSubmit}>


            <Box sx={{ display: "flex", justifyContent: "end", alignItems: "end" }}>
                <Button
                    type="submit"
                    sx={{
                        mt: 3,
                        mb: 1,
                        borderRadius: "12px",
                        backgroundColor: "#F26E21",
                        color: "#fff",
                        textAlign: "center",
                        fontSize: "14px",

                        px: 2,
                        py: 2,
                        minWidth: 200,
                        height: "48px",
                        cursor: "pointer",
                        fontFamily: "'Poppins', sans-serif",
                        textTransform: "capitalize",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        "&:hover": {
                            backgroundColor: "#F26E21", // Keep the same background on hover
                            boxShadow: "none", // Remove box-shadow on hover if any
                        },
                        "&:active": {
                            backgroundColor: "#F26E21", // Keep the same background on click
                            boxShadow: "none", // Remove box-shadow on click if any
                        },
                        "&.Mui-focusVisible": {
                            backgroundColor: "#F26E21", // Keep the same background on focus
                            boxShadow: "none", // Remove box-shadow on focus if any
                        },
                    }}
                >
                    Submit
                </Button>
            </Box>

            {statusMessage && (
                <div
                    style={{ color: statusMessage.type === "success" ? "green" : "red" }}
                >
                    {statusMessage.text}
                </div>
            )}
        </form>
    );
};

export default UpdateManufacturerURLsForm;
