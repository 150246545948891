import React, { useEffect, useState } from 'react';
import {
    TextField,
    Autocomplete,
    Button,
    Box,
    Typography,
    CircularProgress,
} from '@mui/material';
import useFetchMPNs from '../../hooks/useFetchMPNs';
import { FormContainer, StatusMessage } from './AdminCss';
import { sanitizeInput } from '../../helper/helper';
import FileUpload from '../FileUpload/FileUpload';
import useAddXRay from '../../hooks/useAddXRay';
import Papa from 'papaparse';
import fallbackImage from '../../assets/broken_image.png';
import { generatePresignedUrl } from '../../helper/awsUrlParser';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import CSVInput from '../CSVInput/CSVInput';

const AddXRayForm = () => {
    const [formData, setFormData] = useState({
        top_image_url: '',
        side_image_url: '',
        csv_data: '',
    });

    const [resetCSVInput, setResetCSVInput] = useState(false);

    const { addXRay, statusMessage } = useAddXRay();

    const mpns = useFetchMPNs();
    const [selectedMPN, setSelectedMPN] = useState(null);
    const [isTopImageUploading, setIsTopImageUploading] = useState(false);
    const [isSideImageUploading, setIsSideImageUploading] = useState(false);

    useEffect(() => {
        if (statusMessage?.type === 'success') {
            setFormData({
                top_image_url: '',
                side_image_url: '',
                csv_data: '',
            });
            setSelectedMPN(null);
            setResetCSVInput(true);
        }
    }, [statusMessage]);

    const handleChange = (e) => {
        const { name, value, type, checked, files } = e.target;

        if (type === 'file' && files.length > 0) {
            const file = files[0];
            const requiredColumns = ['layer name', 'measurement', 'material'];
            Papa.parse(file, {
                preview: 1,
                header: true,
                complete: (results) => {
                    const columnNames = results.meta.fields;

                    const missingColumns = requiredColumns.filter(
                        (column) => !columnNames.includes(column)
                    );

                    if (missingColumns.length > 0) {
                        console.error(`Missing columns: ${missingColumns.join(', ')}`);
                        alert(`CSV is missing required columns: ${missingColumns.join(', ')}`);
                        setFormData((prev) => ({
                            ...prev,
                            [name]: '',
                        }));
                        return;
                    }

                    if (files.length > 0) {
                        const file = files[0];
                        const reader = new FileReader();

                        reader.onload = function (event) {
                            setFormData((prev) => ({
                                ...prev,
                                [name]: event.target.result,
                            }));
                        };

                        reader.readAsText(file);
                    }
                },
                error: (error) => {
                    console.error('Error reading CSV file:', error);
                    alert('An error occurred while processing the file.');
                },
            });
        } else {
            setFormData((prev) => ({
                ...prev,
                [name]: type === 'checkbox' ? sanitizeInput(checked) : sanitizeInput(value),
            }));
        }
    };

    const handleImageChange = (name, value) => {
        console.log({ name: name, value: value.imageUrl });

        setFormData((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        if (!selectedMPN || !formData.csv_data || !formData.top_image_url || !formData.side_image_url) {
            return alert('Please fill in all fields');
        }
        const payload = {
            name: selectedMPN.name,
            top_image_url: formData.top_image_url,
            side_image_url: formData.side_image_url,
            csv_data: formData.csv_data,
        };

        addXRay(payload);
    };

    const inputStyle = {
        width: '100%',
        marginBottom: '10px',
    };

    const handleImageError = (event) => {
        event.target.src = fallbackImage;
        event.target.style = 'width: 70px; height: 70px; border-radius: 6px;';
    };

    return (
        <FormContainer>
            <Box component="form" onSubmit={handleSubmit} style={{ width: '100%' }}>
                <Autocomplete
                    id="mpn-autocomplete"
                    options={mpns}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="MPN"
                            fullWidth
                            style={inputStyle}
                        />
                    )}
                    value={selectedMPN}
                    onChange={(event, newValue) => setSelectedMPN(newValue)}
                    fullWidth
                />

                <Box sx={{ display: 'flex', gap: 4, marginBottom: '20px' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                        <Typography variant="body1">Top Image</Typography>
                        {isTopImageUploading ? (
                            <Box
                                sx={{
                                    position: 'relative',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    width: '200px',
                                    height: '200px',
                                    border: '1px solid #ccc',
                                    borderRadius: '6px',
                                    cursor: 'pointer',
                                }}
                            >
                                <CircularProgress sx={{ color: '#F26E21' }} />
                            </Box>
                        ) : (
                            formData.top_image_url ? (
                                <Box
                                    sx={{
                                        position: 'relative',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        width: '200px',
                                        height: '200px',
                                        border: '1px solid #ccc',
                                        borderRadius: '6px',
                                        cursor: 'pointer',
                                    }}
                                >
                                    <div style={{
                                        position: 'absolute',
                                        top: 5,
                                        right: 5,
                                        cursor: 'pointer',
                                        backgroundColor: '#F26E21',
                                        borderRadius: '6px',
                                        padding: '4px',
                                        color: '#fff',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}
                                        onClick={() => handleImageChange('top_image_url', '')}>
                                        <DeleteOutlineIcon sx={{ width: '20px', height: '20px' }} />
                                    </div>
                                    <img
                                        src={generatePresignedUrl(formData.top_image_url)}
                                        alt={'Top Image'}
                                        onError={handleImageError}
                                        style={{ maxWidth: '100%', height: 'auto' }}
                                    />
                                </Box>
                            ) : (
                                <FileUpload
                                    name="top_image_url"
                                    accept="image/*"
                                    onChange={(name, value) => {
                                        handleImageChange(name, value);
                                        setIsTopImageUploading(false);
                                    }}
                                    setIsImageUploading={setIsTopImageUploading}
                                />
                            )
                        )}
                    </Box>

                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                        <Typography variant="body1">Side Image</Typography>
                        {isSideImageUploading ? (
                            <Box
                                sx={{
                                    position: 'relative',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    width: '200px',
                                    height: '200px',
                                    border: '1px solid #ccc',
                                    borderRadius: '6px',
                                    cursor: 'pointer'
                                }}
                            >
                                <CircularProgress sx={{ color: '#F26E21' }} />
                            </Box>
                        ) : (
                            formData.side_image_url ? (
                                <Box
                                    sx={{
                                        position: 'relative',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        width: '200px',
                                        height: '200px',
                                        border: '1px solid #ccc',
                                        borderRadius: '6px',
                                        cursor: 'pointer'
                                    }}
                                >
                                    <div
                                        style={{
                                            position: 'absolute',
                                            top: 5,
                                            right: 5,
                                            cursor: 'pointer',
                                            backgroundColor: '#F26E21',
                                            borderRadius: '6px',
                                            padding: '4px',
                                            color: '#fff',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        }}
                                        onClick={() => handleImageChange('side_image_url', '')}>
                                        <DeleteOutlineIcon sx={{ width: '20px', height: '20px' }} />
                                    </div>
                                    <img
                                        src={generatePresignedUrl(formData.side_image_url)}
                                        alt={'Side Image'}
                                        onError={handleImageError}
                                        style={{ maxWidth: '100%', height: 'auto' }}
                                    />
                                </Box>
                            ) : (
                                <FileUpload
                                    name="side_image_url"
                                    accept="image/*"
                                    onChange={(name, value) => {
                                        handleImageChange(name, value);
                                        setIsSideImageUploading(false);
                                    }}
                                    setIsImageUploading={setIsSideImageUploading}
                                />
                            )
                        )}
                    </Box>
                </Box>

                <CSVInput
                    name="csv_data"
                    onChange={handleChange}
                    accept=".csv"
                    style={inputStyle}
                    reset={resetCSVInput}
                />

                <Box sx={{ display: 'flex', justifyContent: 'end', alignItems: 'end' }}>
                    <Button
                        id="submit-button"
                        variant="contained"
                        color="primary"
                        type="submit"
                        disabled={isTopImageUploading || isSideImageUploading}
                        sx={{
                            mt: 3,
                            mb: 1,
                            borderRadius: '12px',
                            backgroundColor: '#F26E21',
                            color: '#fff',
                            textAlign: 'center',
                            fontSize: '14px',
                            px: 2,
                            py: 2,
                            minWidth: 200,
                            height: '48px',
                            cursor: 'pointer',
                            fontFamily: "'Poppins', sans-serif",
                            textTransform: 'capitalize',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            '&:hover': {
                                backgroundColor: '#F26E21',
                                boxShadow: 'none',
                            },
                            '&:active': {
                                backgroundColor: '#F26E21',
                                boxShadow: 'none',
                            },
                            '&.Mui-focusVisible': {
                                backgroundColor: '#F26E21',
                                boxShadow: 'none',
                            },
                        }}
                    >
                        Submit
                    </Button>
                </Box>

                {statusMessage && (
                    <StatusMessage
                        id="status-message"
                        style={{
                            color: statusMessage.type === 'success' ? 'green' : 'red',
                        }}
                    >
                        {statusMessage.text}
                    </StatusMessage>
                )}
            </Box>
        </FormContainer>
    );
};

export default AddXRayForm;