import React, { useState } from "react";
import {
  AppBar,
  Box,
  Toolbar,
  IconButton,
  Typography,
  Tabs,
  Tab,
  Container,
  Avatar,
  Tooltip,
  useMediaQuery,
  useTheme,
  List,
  Slide,
  CssBaseline,
  Drawer,
} from "@mui/material";
import { Link } from "react-router-dom";
import { Menu as MenuIcon, CloseSharp, Person } from "@mui/icons-material";
import useScrollTrigger from "@mui/material/useScrollTrigger";

import logo from "../../assets/logo16x16-removebg-preview.png";
import { NAVBAR_LINKS } from "../../constants/navbar";

const HideOnScroll = ({ children }) => {
  const trigger = useScrollTrigger();
  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
};

const ResponsiveAppBar = (props) => {
  const [anchorElNav, setAnchorElNav] = useState(null);
  const {
    REACT_APP_CLIENT_ID: clientId,
    REACT_APP_REDIRECT_URI: redirectUri,
    REACT_APP_AUTH_DOMAIN: domain,
  } = process.env;
  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.down("md"));

  const handleLogin = () =>
    window.location.assign(
      `https://${domain}/login?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}&scope=email%20openid%20profile&response_mode=fragment&prompt=login`
    );

  const handleSignup = () =>
    window.location.assign(
      `https://${domain}/signup?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}&scope=email%20openid%20profile&response_mode=fragment`
    );

  return (
    <>
      <CssBaseline />
      <HideOnScroll {...props}>
        <AppBar
          style={{
            width: "100%",
            background: "rgba(0, 0, 0, 0.5)",
            backdropFilter: "blur(10px)",
          }}
        >
          <Container maxWidth="xl">
            <Toolbar
              disableGutters
              sx={{ display: "flex", justifyContent: "space-between" }}
            >
              <Box sx={{ display: "flex", gap: 10 }}>
                <Typography
                  variant="h6"
                  noWrap
                  component="a"
                  href="/"
                  id='navbar-logo'
                  className="cursorp"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    fontFamily: "'Poppins', sans-serif",
                    color: "inherit",
                    textDecoration: "none",
                  }}
                >
                  <Avatar sx={{ mr: 1, background: "transparent" }}>
                    <img
                      src={logo}
                      alt="logo"
                      style={{ width: "100%", height: "auto" }}
                    />
                  </Avatar>
                  <Link
                    to={"/"}
                    style={{ textDecoration: "none", color: "#fff" }}
                  >
                    NUCLEON
                  </Link>
                </Typography>
                <Box sx={{ display: { xs: "none", md: "flex" } }}>
                  <Tabs
                    value={0}
                    indicatorColor="transparent"
                    sx={{ margin: "auto" }}
                  >
                    {NAVBAR_LINKS.map((link, index) => (
                      <Tab
                        key={index}
                        id={link.id}
                        label={
                          <Link
                            to={`/${
                              link.href === 'test-solutions' ||
                              link.href === 'blogs'
                                ? link.href
                                : `#${link.href
                                    .replace(/\s+/g, "")
                                    .toLowerCase()}`
                            }`}
                            style={{
                              textDecoration: "none",
                              textTransform: "capitalize",
                              color: "white",
                            }}
                          >
                            {link.label}
                          </Link>
                        }
                      />
                    ))}
                  </Tabs>
                </Box>
              </Box>
              <Box sx={{ flexGrow: 0 }}>
                <Tooltip>
                  {isMatch ? (
                    <IconButton
                      size="medium"
                      color="inherit"
                      onClick={(e) => setAnchorElNav(e.currentTarget)}
                      sx={{
                        backgroundColor: "#F26E21",
                        borderRadius: 1,
                        "&:hover": { backgroundColor: "#F26E21", opacity: 0.5 },
                      }}
                    >
                      <MenuIcon />
                    </IconButton>
                  ) : (
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Person />
                      <Typography
                        sx={{ cursor: "pointer", padding: "0.5rem" }}
                        onClick={handleSignup}
                      >
                        Register
                      </Typography>{" "}
                      /
                      <Typography
                        sx={{ cursor: "pointer", padding: "0.5rem" }}
                        onClick={handleLogin}
                      >
                        Sign In
                      </Typography>
                    </Box>
                  )}
                </Tooltip>
                <Drawer
                  anchor="top"
                  open={Boolean(anchorElNav)}
                  onClose={() => setAnchorElNav(null)}
                >
                  <Container
                    sx={{ height: "100vh", padding: "20px", color: "#000" }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginBottom: "20px",
                      }}
                    >
                      <Typography
                        variant="h6"
                        noWrap
                        component="a"
                        href="/"
                        className="cursorp"
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          fontFamily: "monospace",
                          color: "inherit",
                          textDecoration: "none",
                        }}
                      >
                        <Avatar sx={{ mr: 1, background: "transparent" }}>
                          <img
                            src={logo}
                            alt="logo"
                            style={{ width: "100%", height: "auto" }}
                          />
                        </Avatar>
                        <Link
                          to={"/"}
                          style={{ textDecoration: "none", color: "black" }}
                        >
                          NUCLEON
                        </Link>
                      </Typography>
                      <IconButton
                        size="medium"
                        onClick={() => setAnchorElNav(null)}
                        sx={{
                          backgroundColor: "#F26E21",
                          borderRadius: 1,
                          "&:hover": {
                            backgroundColor: "#F26E21",
                            opacity: 0.5,
                          },
                        }}
                      >
                        <CloseSharp sx={{ color: "white" }} />
                      </IconButton>
                    </Box>
                    <List
                      sx={{ display: "flex", flexDirection: "column", gap: 3 }}
                    >
                      {NAVBAR_LINKS.map((link, index) => (
                        <Link
                          key={index}
                          to={`/${
                              link.href === 'test-solutions' ||
                              link.href === 'blogs'
                              ? link.href
                              : `#${link.href
                                  .replace(/\s+/g, "")
                                  .toLowerCase()}`
                          }`}
                          style={{
                            textDecoration: "none",
                            textTransform: "capitalize",
                            color: "black",
                          }}
                          onClick={() => setAnchorElNav(null)}
                        >
                          {link.label}
                        </Link>
                      ))}
                      <Typography
                        onClick={handleSignup}
                        sx={{ cursor: "pointer" }}
                      >
                        SignUp
                      </Typography>
                      <Typography
                        onClick={handleLogin}
                        sx={{ cursor: "pointer" }}
                      >
                        SignIn
                      </Typography>
                    </List>
                  </Container>
                </Drawer>
              </Box>
            </Toolbar>
          </Container>
        </AppBar>
      </HideOnScroll>
    </>
  );
};

export default ResponsiveAppBar;
