import React, { useState } from "react";
import { Box, Button } from "@mui/material";
import HowToSell from "./HowToSell";
import SubmitData from "./SubmitData";
import UserSubmissions from "./UserSubmissions";
import PageHeader from "../PageHeader/page-header";

const SellFlow = () => {
  const [value, setValue] = useState(0);

  const handleChange = (newValue) => {
    setValue(newValue);
  };

  return (
    <Box id="dashboard-sell-flow-container" sx={{ display: "flex", flexDirection: "column", height: '100%', width: "100%", gap: '10px' }}>

      <PageHeader
        title="Sell data parts"
        breadcrumbs={[
          { label: "Dashboard", href: "/dashboard" },
          { label: "Sell data Parts", href: "/dashboard/sell-data-parts" },
        ]}
        showButton={false}
        sx={{ mb: "20px" }}
      />


      <HeaderAndTabComponent value={value} handleChange={handleChange} />
      {value === 0 && <HowToSell />}
      {value === 1 && <SubmitData />}
      {value === 2 && <UserSubmissions />}
    </Box>
  );
};

const HeaderAndTabComponent = ({ value, handleChange }) => {
  const tabValues = ["How To Sell", "Submit Data", "Data Submission Status"];

  return (
    <Box
      id="dashboard-sell-flow-header-and-tab-container"
      sx={{
        borderColor: "rgba(145 158 171 / 0.2)",
        display: "flex",
        bgcolor: "white",
        gap: 2,
      }}
    >
      {tabValues.map((tab, index) => (
        <Button
          id={`${tab.toLowerCase().replaceAll(' ', '-')}-how-to-sell-tab`}
          key={tab}
          onClick={() => handleChange(index)}
          sx={{
            mt: 2,
            mb: 3,
            borderRadius: "12px",
            backgroundColor: value === index ? "#F26E21" : "#fff", // Orange when active, white otherwise
            border: "1px solid #F26E21",
            color: value === index ? "#fff" : "#F26E21", // White text when active, orange otherwise
            textAlign: "center",
            fontSize: "14px",
            px: 3,
            py: 1,
            cursor: "pointer",
            fontFamily: "'Poppins', sans-serif",
            textTransform: "capitalize",
            display: "block",
            "&:hover": {
              backgroundColor: value === index ? "#F26E21" : "#fff", // Maintain the same background on hover
              color: value === index ? "#fff" : "#F26E21", // Maintain the same text color on hover
              borderColor: "#F26E21", // Maintain the same border
            },
            "&:active": {
              backgroundColor: "#F26E21", // Orange background on click
              color: "#fff", // White text on click
              borderColor: "#F26E21", // Keep border orange
            },
            "&.Mui-focusVisible": {
              backgroundColor: value === index ? "#F26E21" : "#fff", // Maintain the same background on focus
              color: value === index ? "#fff" : "#F26E21", // Maintain the same text color on focus
              borderColor: "#F26E21", // Maintain the same border color
            },
          }}
          variant={value === index ? "contained" : "text"}
        >
          {tab}
        </Button>
      ))}
    </Box>
  );
};

export default SellFlow;
