import React, { useState } from 'react';
import {
    Modal,
    Box,
    Typography,
    Checkbox,
} from '@mui/material';
import NucleonButton from '../Dashboard/NucleonButton/nucleon-button';
import { termsAndConditions } from '../../constants/terms-and-policy';

const TermsAndConditionsModal = ({ open, handleClose, type, handleSubmit = () => { }, handleLogout = () => { } }) => {
    const [isTermsAndConditionsAccepted, setIsTermsAndConditionsAccepted] = useState(false);


    const renderContent = (content) => {
        if (Array.isArray(content)) {
            return content.map((subContent, subIndex) => (
                <div key={subIndex}>
                    {subContent.heading && (
                        <Typography sx={{ fontWeight: 'bold' }} variant="subtitle1" component="h4" gutterBottom>
                            {subContent.heading}
                        </Typography>
                    )}
                    {subContent.content.split('<br/>').map((para, paraIndex) => (
                        <Typography key={paraIndex} variant="body1" component="p" gutterBottom>
                            {parseBoldText(para)}
                        </Typography>
                    ))}
                </div>
            ));
        } else {
            return content.split('<br/>').map((para, paraIndex) => (
                <Typography key={paraIndex} variant="body1" component="p" gutterBottom>
                    {parseBoldText(para)}
                </Typography>
            ));
        }
    };

    const parseBoldText = (text) => {
        const parts = text.split(/(<b>[^<>]*<\/b>)/g);
        return parts.map((part, index) => {
            if (part.startsWith('<b>') && part.endsWith('</b>')) {
                return (
                    <span key={index} style={{ fontWeight: 600 }}>
                        {part.slice(3, -4)}
                    </span>
                );
            }
            return <span key={index}>{part}</span>;
        });
    };


    return (
        <Modal open={open} onClose={handleClose}>
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                maxWidth: '800px',
                maxHeight: '90vh',
                overflow: 'auto',
                bgcolor: '#dadada',
                p: 4,
                borderRadius: 3,
                outline: 'none',
                boxShadow: 'none',
                '&:focus-visible': {
                    outline: 'none',
                },
            }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', backgroundColor: '#ececec', p: 1, maxHeight: '70vh', overflow: 'auto', borderRadius: 3 }}>
                    <Typography sx={{ fontWeight: 'bold' }} variant="h6" component="h2" gutterBottom>
                        WEBSITE TERMS OF USE
                    </Typography>
                    <Typography sx={{ fontWeight: 'bold' }} variant="bo dy1" gutterBottom>
                        VERSION 1.0
                    </Typography>
                    <Typography sx={{ fontWeight: 'bold', mb: 3 }} variant="body2" gutterBottom>
                        LAST REVISED ON: 3/20, 2023
                    </Typography>
                    {termsAndConditions.map((section, index) => (
                        <div key={index}>
                            {section.heading && (
                                <Typography sx={{ fontWeight: 'bold' }} variant="h6" component="h3" gutterBottom>
                                    {section.heading}
                                </Typography>
                            )}
                            {renderContent(section.content)}
                        </div>
                    ))}
                </Box>
                {type === 'dashboard' ? (
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start', mt: 2, justifyContent: 'center' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', mb: 1.5, }}>
                            <Checkbox
                                sx={{
                                    color: '#F26E21',
                                    '&.Mui-checked': {
                                        color: '#F26E21',
                                    },
                                }}
                                checked={isTermsAndConditionsAccepted}
                                onChange={(event) => setIsTermsAndConditionsAccepted(event.target.checked)}
                            />
                            <Typography sx={{ mt: 0.5 }} gutterBottom>
                                Yes, I accept the terms and conditions
                            </Typography>
                        </Box>
                        <NucleonButton isActive handleButtonClick={isTermsAndConditionsAccepted ? handleSubmit : handleLogout} >
                            {isTermsAndConditionsAccepted ? 'Accept' : 'Log Out'}
                        </NucleonButton>
                    </Box>
                ) : (
                    <Box sx={{ mt: 2 }}>
                        <NucleonButton isActive={true} handleButtonClick={handleClose} >
                            Close
                        </NucleonButton>
                    </Box>
                )}

            </Box>
        </Modal>
    );
};

export default TermsAndConditionsModal;