import React from 'react';
import DatabaseCard from '../components/DatabaseCard/DatabaseCard';
import MarketplaceCard from '../components/MarketplaceCard/MarketplaceCard';
import RadiationTestingCard from '../components/RadiationTestingCard/RadiationTestingCard';
import AdvancedFeaturesCard from '../components/AdvancedFeaturesCard/AdvancedFeaturesCard';

import ListIcon from '@mui/icons-material/List';
import CategoryIcon from '@mui/icons-material/Category';
import UpdateIcon from '@mui/icons-material/Update';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import ScienceIcon from '@mui/icons-material/Science';
import CalculatorIcon from '@mui/icons-material/Calculate';
import FilterListIcon from '@mui/icons-material/FilterList';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import { Assessment, Construction, Factory } from '@mui/icons-material';
import DataBaseIcon from '../assets/database.webp';
import MarketplaceIcon from '../assets/marketplace.png';
import RadiationTestingIcon from '../assets/radiation.png';
import AdvancedFeaturesIcon from '../assets/satellite.webp';

export const SERVICES = [
    {
        label: "Database", icon: DataBaseIcon, content: <DatabaseCard />,
        id: 'services-database-card',
        details: {
            image: "database.jpeg",
            title: "Access up-to-date radiation test data in an expert-curated, searchable database",
            description: "Access up-to-date radiation test data in an expert-curated, searchable database",
            comingSoon: false,
            advantages: [
                { icon: <ListIcon sx={{ color: '#F26E21' }} />, label: "Curated compilation of test results from public and private sources" },
                { icon: <CategoryIcon sx={{ color: '#F26E21' }} />, label: "Categorized data with key results extraction" },
                { icon: <UpdateIcon sx={{ color: '#F26E21' }} />, label: "Continuously refreshed, with provision for user-requested updates" }],
            statistics: [
                { label: "Parts", value: 5556, icon: <Construction sx={{ fontSize: { xs: '1.4rem', sm: '1.8rem', md: '2.2rem' }, color: '#F26E21' }} /> },
                { label: "Test results", value: 7113, icon: <Assessment sx={{ fontSize: { xs: '1.4rem', sm: '1.8rem', md: '2.2rem' }, color: '#F26E21' }} /> },
                { label: "Manufacturers", value: 415, icon: <Factory sx={{ fontSize: { xs: '1.4rem', sm: '1.8rem', md: '2.2rem' }, color: '#F26E21' }} /> }
            ]
        }
    },
    {
        label: "Marketplace", id: 'services-marketplace-card', icon: MarketplaceIcon, content: <MarketplaceCard />, details: {
            image: "data_marketplace_image.jpg",
            title: "Nucleon Data Marketplace",
            description: "Nucleon Data Marketplace",
            comingSoon: true,
            advantages: [
                { icon: <ListIcon sx={{ color: '#F26E21' }} />, label: "Securely trade radiation effects test reports" },
                { icon: <CategoryIcon sx={{ color: '#F26E21' }} />, label: "Report quality ensured with expert review" },
                { icon: <UpdateIcon sx={{ color: '#F26E21' }} />, label: "Subscription proceeds contribute to database expansion" }],
            statistics: [
                { label: "New test results", value: 535, icon: <Assessment sx={{ fontSize: { xs: '1.4rem', sm: '1.8rem', md: '1.8rem' }, color: '#F26E21' }} /> },
            ]
        }
    },
    {
        label: "Radiation Testing", id: 'services-radiation-testing-card', icon: RadiationTestingIcon, content: <RadiationTestingCard />, details: {
            image: "cyclotron.jpeg",
            title: "Nucleon Radiation Testing",
            description: "Nucleon Radiation Testing",
            comingSoon: false,
            advantages: [
                { icon: <AccessTimeIcon sx={{ color: '#F26E21' }} />, label: "Subscribers have opportunity to participate in quarterly test campaigns" },
                { icon: <MonetizationOnIcon sx={{ color: '#F26E21' }} />, label: "Receive discounts by allowing publication of test data" },
                { icon: <ScienceIcon sx={{ color: '#F26E21' }} />, label: "Leverage our partnerships with multiple facilities for heavy-ion, proton, total-ionizing dose, and displacement damage testing" }],
            statistics: []
        }
    },
    {
        label: "Mission Environment", id: 'services-advanced-features-card', icon: AdvancedFeaturesIcon, content: <AdvancedFeaturesCard />, details: {
            image: "mission_env_image_big.jpg",
            title: "Mission Environment",
            description: "Choose the parts that are right for your mission",
            comingSoon: false,
            advantages: [
                { icon: <CalculatorIcon sx={{ color: '#F26E21' }} />, label: "Access user-friendly tools to calculate mission radiation environments" },
                { icon: <FilterListIcon sx={{ color: '#F26E21' }} />, label: "Seamlessly integrate calculations with database filters for electronic part selection" },
                { icon: <ThumbUpIcon sx={{ color: '#F26E21' }} />, label: "Receive expert-reviewed recommendations for optimal part choices" }],
            statistics: []
        }
    }
];