import React from 'react';
import {
    Modal,
    Box,
    Typography,
} from '@mui/material';
import NucleonButton from '../Dashboard/NucleonButton/nucleon-button';
import { privacyPolicy } from '../../constants/terms-and-policy';

const PrivacyPolicyModal = ({ open, handleClose }) => {

    const renderContent = (content) => {
        if (Array.isArray(content)) {
            return content.map((subContent, subIndex) => (
                <div key={subIndex}>
                    {subContent.heading && (
                        <Typography variant="subtitle1" style={{ fontStyle: 'italic' }} component="h4" gutterBottom>
                            {subContent.heading}
                        </Typography>
                    )}
                    {subContent.content.split('<br/>').map((para, paraIndex) => (
                        <Typography key={paraIndex} variant="body1" component="p" gutterBottom>
                            {para}
                        </Typography>
                    ))}
                </div>
            ));
        } else {
            return content.split('<br/>').map((para, paraIndex) => (
                <Typography key={paraIndex} variant="body1" component="p" gutterBottom>
                    {para}
                </Typography>
            ));
        }
    };

    return (
        <Modal open={open} onClose={handleClose}>
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                maxWidth: '800px',
                maxHeight: '90vh',
                overflow: 'auto',
                bgcolor: '#dadada',
                p: 4,
                borderRadius: 3,
                outline: 'none',
                boxShadow: 'none',
                '&:focus-visible': {
                    outline: 'none',
                },
            }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', backgroundColor: '#ececec', p: 1, maxHeight: '70vh', overflow: 'auto', borderRadius: 3 }}>
                    <Typography variant="h6" component="h2" gutterBottom>
                        Cyclo Technologies, Inc Privacy Policy
                    </Typography>
                    {privacyPolicy.map((section, index) => (
                        <div key={index}>
                            {section.heading && (
                                <Typography variant="h6" component="h3" gutterBottom>
                                    {section.heading}
                                </Typography>
                            )}
                            {renderContent(section.content)}
                        </div>
                    ))}
                </Box>
                <Box sx={{ mt: 2 }}>
                    <NucleonButton isActive={true} handleButtonClick={handleClose} >
                        Close
                    </NucleonButton>
                </Box>
            </Box>
        </Modal>
    );
};

export default PrivacyPolicyModal;