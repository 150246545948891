import { useState } from 'react';

const useAddPart = () => {
    const [statusMessage, setStatusMessage] = useState(null);

    const addPart = async (payload) => {
        setStatusMessage(null);

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/operations/add_part`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                credentials: 'include',
                body: JSON.stringify( payload)
            });

            const data = await response.json();
            if (response.ok) {
                setStatusMessage({ type: 'success', text: 'Part added successfully!' });
            } else {
                throw new Error(data.message || 'Failed to add part');
            }
        } catch (error) {
            setStatusMessage({ type: 'error', text: error.message });
        }
    };

    return { addPart, statusMessage };
};

export default useAddPart;