import React, { useState, useRef } from 'react';
import { Button, Box, Typography } from '@mui/material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

const CSVInput = ({ name, onChange, accept, style, reset }) => {
    const fileInputRef = useRef();
    const [selectedFile, setSelectedFile] = useState(null);

    const handleFileSelect = (event) => {
        const file = event.target.files[0];
        setSelectedFile(file);
        if (onChange) {
            onChange(event);
        }
    };

    const handleRemoveFile = () => {
        fileInputRef.current.value = null;
        setSelectedFile(null);
        if (onChange) {
            onChange({ target: { name, files: [] } });
        }
    };

    React.useEffect(() => {
        if (reset) {
            handleRemoveFile();
        }
    }, [reset]);

    return (
        <Box style={{
            position: 'relative',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            border: '1px solid #ccc',
            borderRadius: '6px',
            gap: '20px',
            ...style
        }}>
            <Button
                variant="outlined"
                component="span"
                onClick={() => fileInputRef.current.click()}
                sx={{
                    padding: '10px 16px',
                    WebkitBorderTopRightRadius: '0px',
                    WebkitBorderBottomRightRadius: '0px',
                    borderTop: 'none',
                    borderBottom: 'none',
                    borderLeft: 'none',
                    backgroundColor: '#F26E21',
                    color: '#fff',
                    '&:hover': {
                        backgroundColor: '#F26E21',
                        borderTop: 'none',
                        borderBottom: 'none',
                        borderLeft: 'none',
                    },
                }}
            >
                Select CSV File
            </Button>
            <Typography variant="body2" color="textSecondary">
                {selectedFile ? selectedFile.name : "No file selected"}
            </Typography>
            <input
                type="file"
                name={name}
                ref={fileInputRef}
                onChange={handleFileSelect}
                accept={accept}
                style={{ display: 'none' }}
            />
            {selectedFile &&
                <Button
                    variant="outlined"
                    component="span"
                    onClick={handleRemoveFile}
                    sx={{
                        position: 'absolute',
                        right: 0,
                        borderColor: '#ccc',
                        WebkitBorderTopLeftRadius: '0px',
                        WebkitBorderBottomLeftRadius: '0px',
                        alignSelf: 'end',
                        padding: '10px 16px',
                        borderTop: 'none',
                        borderBottom: 'none',
                        borderRight: 'none',
                        backgroundColor: '#F26E21',
                        color: '#fff',
                        '&:hover': {
                            backgroundColor: '#F26E21',
                            borderTop: 'none',
                            borderBottom: 'none',
                            borderRight: 'none',
                        },
                    }}
                >
                    <DeleteOutlineIcon sx={{ width: '25px', height: '25px' }} />
                </Button>
            }
        </Box>
    );
};

export default CSVInput;