import { useState } from 'react';

const useUpdatePCNs = () => {
    const [statusMessage, setStatusMessage] = useState(null);

    const updatePCNs = async () => {
        setStatusMessage(null);
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/operations/update_pcns`, {
                method: 'post',
                credentials: 'include'
            });
            const data = await response.json();
            if (response.ok) {
                setStatusMessage({ type: 'success', text: 'PCNs successfully updated' });
            } else {
                console.log(data);
                throw new Error('Failed to update PCNs.');
            }
        } catch (error) {
            setStatusMessage({ type: 'error', text: error.message });
        }
    };

    // Return the function and status message
    return { updatePCNs, statusMessage };
};

export default useUpdatePCNs;
