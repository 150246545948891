import React from "react";
import { Routes, Route } from "react-router-dom";

import PartDetails from "../components/Dashboard/PartDetails/PartDetails";
import Account from "../components/Account/Account";
import Analysis from "../components/Dashboard/Analysis/Analysis";
import BillOfMaterials from "../components/Dashboard/BillOfMaterials/BillOfMaterials";
import BOMDetails from "../components/Dashboard/BillOfMaterials/BOMDetails";
import MarketplaceDataTable from "../components/Dashboard/Marketplace/MarketplaceDataTable";
// import CategoryModels from "../components/Dashboard/CategoryModels/CategoryModels";
// import CategoryDetails from "../components/Dashboard/CategoryModels/CategoryDetails";
import Admin from "../components/Admin/Admin";
import SellFlow from "../components/Dashboard/Marketplace/SellFlow";
import MarketplaceAdmin from "../components/MarketplaceAdmin/MarketplaceAdmin";
import WikiPage from "../components/Dashboard/Wiki/Wiki";
import DashboardLayout from "../components/Layout/Dashboard";
import RadiationTestDataTable from "../components/Dashboard/RadiationTestDataTable/radiation-test-data-table";

const DashboardRoutes = () => {
  return (
    <Routes>
      <Route element={<DashboardLayout />}>
        <Route index element={<RadiationTestDataTable />} />
        <Route
          path="parts/:manufacturer/:partNumber"
          element={<PartDetails key={window.location.pathname} />}
        />
        <Route path="account" element={<Account />} />
        {/* <Route path="category-models" element={<CategoryModels />} />
        <Route
          path="categories/:categoryName"
          element={<CategoryDetails key={window.location.pathname} />}
        /> */}
        <Route path="analysis" element={<Analysis />} />
        <Route path="bill-of-materials" element={<BillOfMaterials />} />
        <Route path="bill-of-materials/:bomId" element={<BOMDetails />} />
        <Route path="Marketplace" element={<MarketplaceDataTable />} />
        <Route path="SellFlow" element={<SellFlow />} />
        <Route path="wiki" element={<WikiPage />} />
        <Route path="operations" element={<Admin />} />
        <Route path="market-place-admin" element={<MarketplaceAdmin />} />
      </Route>
    </Routes>
  );
};

export default DashboardRoutes;
