import React, { useState } from 'react';
import { TextField, Button, Box } from '@mui/material';
import { sanitizeInput } from '../../helper/helper';

const AddManufacturerForm = () => {

  const [manufacturerName, setManufacturer] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/operations/add-manufacturer`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        credentials: 'include',
        body: JSON.stringify({
          manufacturerName: manufacturerName,
        })
      });

      if (response.ok) {
        console.log('Alias added successfully');
        // Optionally, you can reset the form here or show a success message
      } else {
        throw new Error('Failed to add alias');
      }
    } catch (error) {
      console.log({ type: 'error', text: error.message });
    }
  };

  return (
    <Box
      id='add-manufacturer-form'
      component="form"
      onSubmit={handleSubmit}

    >
      {/* <TextField
                label="Manufacturer Name"
                value={manufacturerName}
                onChange={(e) => setManufacturer(e.target.value)}
                variant="outlined"
                sx={{
                    backgroundColor: 'white', // Background color of the TextField
                    color: 'white', // Text color
                    '& .MuiInputBase-input': {
                        color: 'black', // Text color inside the input
                        backgroundColor: 'white' // Background color inside the input
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'white' // Border color
                    },
                    '& .MuiInputLabel-root': {
                        color: 'black' // Label color
                    }
                }}
            /> */}
      {/* <Button
                variant="contained"
                color="primary"
                type="submit"
                sx={{
                    backgroundColor: 'blue',
                    color: 'white',
                    '&:hover': {
                        backgroundColor: 'darkblue'
                    }
                }}
            >
                Add Manufacturer
            </Button> */}





      <TextField
        id="manufacturer-name-input"
        style={{
          width: "100%",
          marginRight: "0",
          marginBottom: "10px",
          marginTop: "20px"
        }}
        label="Manufacturer Name"
        value={manufacturerName}
        onChange={(e) => setManufacturer(sanitizeInput(e.target.value))}
        variant="outlined"
        sx={{
          "& .MuiOutlinedInput-root": {
            borderRadius: 2,
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: "black", // Set focus border color to black
            },
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "rgba(145, 158, 171, 0.8)", // Example hover effect with 0.8 opacity
          },
          "& .MuiOutlinedInput-notchedOutline": {
            borderRadius: 2,
            borderColor: "rgba(145, 158, 171, 0.2)",
          },
          "& .MuiInputLabel-root": {
            color: "rgba(145 158 171)", // Initial label color
            "&.Mui-focused": {
              color: "black", // Label color on focus
            },
          },
          "& .MuiInputBase-input::placeholder": {
            color: "rgba(145, 158, 171, 0.8)", // Placeholder text color
            opacity: 1, // Ensure full opacity
          },
          "& .MuiInputBase-input:focus::placeholder": {
            color: "rgba(145, 158, 171, 0.8)", // Placeholder text color on focus
          },
        }}
      />

      <Box sx={{ display: "flex", justifyContent: "end", alignItems: "end" }}>
        <Button
          type="submit"
          sx={{
            mt: 3,
            mb: 1,
            borderRadius: "12px",
            backgroundColor: "#F26E21",
            color: "#fff",
            textAlign: "center",
            fontSize: "14px",

            px: 2,
            py: 2,
            minWidth: 200,
            height: "48px",
            cursor: "pointer",
            fontFamily: "'Poppins', sans-serif",
            textTransform: "capitalize",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            "&:hover": {
              backgroundColor: "#F26E21", // Keep the same background on hover
              boxShadow: "none", // Remove box-shadow on hover if any
            },
            "&:active": {
              backgroundColor: "#F26E21", // Keep the same background on click
              boxShadow: "none", // Remove box-shadow on click if any
            },
            "&.Mui-focusVisible": {
              backgroundColor: "#F26E21", // Keep the same background on focus
              boxShadow: "none", // Remove box-shadow on focus if any
            },
          }}
        >
          Add Manufacturer
        </Button>
      </Box>



    </Box>
  );
};

export default AddManufacturerForm;
