import React from "react";
import useDownloadAttributes from "../../hooks/useDownloadAttributes";
import { Box, Button } from "@mui/material";

const DownloadAttributesForm = () => {
    const { downloadAttributes, statusMessage } = useDownloadAttributes();

    const handleSubmit = async (event) => {
        event.preventDefault();
        downloadAttributes();
    };

    return (
        <form onSubmit={handleSubmit}>
            <Box sx={{ display: "flex", justifyContent: "end", alignItems: "end" }}>
                <Button
                    type="submit"
                    sx={{
                        mt: 3,
                        mb: 1,
                        borderRadius: "12px",
                        backgroundColor: "#F26E21",
                        color: "#fff",
                        textAlign: "center",
                        fontSize: "14px",
                        px: 2,
                        py: 2,
                        minWidth: 200,
                        height: "48px",
                        cursor: "pointer",
                        fontFamily: "'Poppins', sans-serif",
                        textTransform: "capitalize",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        "&:hover": {
                            backgroundColor: "#F26E21",
                            boxShadow: "none",
                        },
                        "&:active": {
                            backgroundColor: "#F26E21",
                            boxShadow: "none",
                        },
                        "&.Mui-focusVisible": {
                            backgroundColor: "#F26E21",
                            boxShadow: "none",
                        },
                    }}
                >
                    Download
                </Button>
            </Box>

            {statusMessage && (
                <div
                    style={{ color: statusMessage.type === "success" ? "green" : "red" }}
                >
                    {statusMessage.text}
                </div>
            )}
        </form>
    );
};

export default DownloadAttributesForm;
