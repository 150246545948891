import React, { useEffect, useState } from 'react';
import {
    Typography,
    Table,
    TableBody,
    TableCell,
    TableRow,
    TableHead,
    Box,
    Button,
} from '@mui/material';
import fallbackImage from '../../../assets/broken_image.png';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { generatePresignedUrl } from '../../../helper/awsUrlParser';

const PartXRay = ({ partDetails }) => {
    const [csvData, setCsvData] = useState([]);
    const [showXRayImage, setShowXRayImage] = useState(true);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [parsedImages, setParsedImages] = useState([]);

    function parseCsvData(index) {
        const xrayLog = partDetails['X-Ray Logs'][index];
        if (xrayLog) {
            const jsonData = JSON.parse(xrayLog.csv_data);
            setCsvData(jsonData);
        }
    }

    useEffect(() => {
        const images = partDetails['X-Ray Logs'].map(log => ({
            frontImage: generatePresignedUrl(log.top_image_url),
            backImage: generatePresignedUrl(log.side_image_url),
        }));

        setParsedImages(images);
    }, [partDetails]);

    useEffect(() => {
        parseCsvData(currentIndex);
    }, [currentIndex]);

    const handleImageError = (event) => {
        event.target.src = fallbackImage;
    };

    const handleNext = () => {
        if (currentIndex < partDetails['X-Ray Logs'].length - 1) {
            setCurrentIndex(currentIndex + 1);
        }
    };

    const handlePrevious = () => {
        if (currentIndex > 0) {
            setCurrentIndex(currentIndex - 1);
        }
    };

    return (
        <Box
            sx={{
                margin: 2,
                display: 'flex',
                flexDirection: 'column',
                backgroundColor: 'white',
                boxShadow:
                    'rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px',
                borderRadius: '16px',
                padding: '16px 16px 16px 24px',
            }}
            id="part-parametric-info-card"
        >
            <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                marginBottom="30px"
            >
                <Typography
                    sx={{
                        textAlign: 'left',
                        fontSize: 22,
                        fontFamily: "'Poppins', sans-serif",
                        fontWeight: 500,
                    }}
                    variant="subtitle1"
                    id="part-parametric-info-title"
                >
                    Part X-Ray
                </Typography>
                <Typography
                    sx={{
                        textAlign: 'left',
                        fontSize: 12,
                        fontFamily: "'Poppins', sans-serif",
                        userSelect: 'none',
                        ":hover": {
                            cursor: 'pointer',
                            textDecoration: 'underline',
                            color: '#F26E21',
                        }
                    }}
                    id="part-parametric-info-title"
                    onClick={() => setShowXRayImage(!showXRayImage)}
                >
                    {showXRayImage ? 'Show Measurement Table' : 'Show X-Ray Image'}
                </Typography>
            </Box>
            <Box sx={{ height: '200px', overflow: 'auto' }}>
                {showXRayImage ? (
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', height: '100%', gap: 2 }}>
                        <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            width={'100%'}
                            height={'100%'}
                            border={1}
                            borderColor="grey.300"
                            borderRadius={4}
                            overflow="hidden"
                        >
                            <img
                                src={parsedImages[currentIndex]?.frontImage || ''}
                                alt="Front X-Ray"
                                style={{ maxWidth: '100%', maxHeight: '100%' }}
                                onError={handleImageError}
                            />
                        </Box>
                        <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            width={'100%'}
                            height={'100%'}
                            border={1}
                            borderColor="grey.300"
                            borderRadius={4}
                            overflow="hidden"
                        >
                            <img
                                src={parsedImages[currentIndex]?.backImage || ''}
                                alt="Back X-Ray"
                                style={{ maxWidth: '100%', maxHeight: '100%' }}
                                onError={handleImageError}
                            />
                        </Box>
                    </Box>
                ) : (
                    <Table size="small" sx={{ width: '100%' }}>
                        <TableHead>
                            <TableRow sx={{ backgroundColor: '#F26E21', position: 'sticky', top: 0, zIndex: 1 }}>
                                <TableCell sx={{ color: 'white' }}>Layer Name</TableCell>
                                <TableCell sx={{ color: 'white' }}>Measurement</TableCell>
                                <TableCell sx={{ color: 'white' }}>Material</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {csvData.length > 0 ? (
                                csvData.map((row, index) => (
                                    <TableRow key={index}>
                                        <TableCell>{row['layer name']}</TableCell>
                                        <TableCell>{row['measurement']}</TableCell>
                                        <TableCell>{row['material']}</TableCell>
                                    </TableRow>
                                ))
                            ) : (
                                <TableRow>
                                    <TableCell colSpan={3}>
                                        <Typography
                                            id="no-parametric-data"
                                            sx={{
                                                textAlign: 'center',
                                                fontSize: 16,
                                                fontFamily: "'Poppins', sans-serif",
                                                fontWeight: 400,
                                                color: '#637381',
                                                mt: 2,
                                            }}
                                        >
                                            No Part X-Ray Data Available
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                )}
            </Box>
            <Box sx={{ marginTop: 2, display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                <Button
                    onClick={handlePrevious}
                    disabled={currentIndex === 0}
                    size='small'
                    sx={{ color: currentIndex === 0 ? 'disabled' : '#F26E21', minWidth: 0 }}
                >
                    <ChevronLeftIcon />
                </Button>
                <Typography sx={{ margin: '0 8px', fontSize: 14, userSelect: 'none', fontFamily: "'Poppins', sans-serif", color: '#637381' }}>
                    {currentIndex + 1} of {partDetails['X-Ray Logs'].length}
                </Typography>
                <Button
                    onClick={handleNext}
                    disabled={currentIndex === partDetails['X-Ray Logs'].length - 1}
                    size='small'
                    sx={{ color: currentIndex === partDetails['X-Ray Logs'].length - 1 ? 'disabled' : '#F26E21', minWidth: 0 }}
                >
                    <ChevronLeftIcon sx={{ transform: 'rotate(180deg)', }} />
                </Button>
            </Box>
        </Box>
    );
};

export default React.memo(PartXRay);