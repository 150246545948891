import React, { useEffect, useState } from "react";
import { Typography } from "@mui/material";
import Plot from "react-plotly.js";
import NucleonWrapper from "../../Wrapper/NucleonWrapper";

const SpaceRatedLETBoxPlot = () => {
  const [plotData, setPlotData] = useState([]);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/dashboard/get_let_space_rated_comparison`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: 'include',
    })
      .then(response => response.json())
      .then(data => {
        const newData = Object.keys(data).map(manufacturer => ({
          y: data[manufacturer],
          type: 'box',
          name: manufacturer,
          boxpoints: 'all',
          jitter: 1,
        }));
        setPlotData(newData);
      })
      .catch(error => console.error('Error:', error));
  }, []);

  return (
    <NucleonWrapper sx={{ mt: 5 }}>
      <Typography
        variant="h6"
        gutterBottom
        sx={{ fontFamily: "'Poppins', sans-serif", color: "#1C252E" }}
      >
        Commercial vs Space Rated LET Comparison
      </Typography>
      <Plot
        divId='let_space_rated_comparison_box_plot'
        data={plotData}
        layout={{
          title:
            "Max Passing LET comparison between Commercial and Space Rated Parts",
          yaxis: { title: "LET Value" },
          boxmode: "group",
        }}
        style={{ width: "100%", height: "600px" }}
      />
    </NucleonWrapper>
  );
};

export default SpaceRatedLETBoxPlot;
