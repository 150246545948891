import React from "react";
import {
  Dashboard as DashboardIcon,
  // Category as CategoryIcon,
  ShowChart as ShowChartIcon,
  Description as BillOfMaterialsIcon,
  Storefront as StorefrontIcon,
  AttachMoney as AttachMoneyIcon,
  Construction as ConstructionIcon,
  AutoStories as AutoStoriesIcon,
} from "@mui/icons-material";

export const NAV_ITEMS = [
  {
    to: "/dashboard",
    icon: <DashboardIcon sx={{ color: "#637381" }} />,
    text: "Dashboard",
  },
  // {
  //   to: "/dashboard/category-models",
  //   icon: <CategoryIcon sx={{ color: "#637381" }} />,
  //   text: "Category Models",
  // },
  {
    to: "/dashboard/analysis",
    icon: <ShowChartIcon sx={{ color: "#637381" }} />,
    text: "Analysis",
  },
  {
    to: "/dashboard/bill-of-materials",
    icon: <BillOfMaterialsIcon sx={{ color: "#637381" }} />,
    text: "Bill of Materials",
  },
  {
    to: "/dashboard/marketplace",
    icon: <StorefrontIcon sx={{ color: "#637381" }} />,
    text: "Marketplace",
  },
  {
    to: "/dashboard/SellFlow",
    icon: <AttachMoneyIcon sx={{ color: "#637381" }} />,
    text: "Sell Parts Data",
  },
  {
    to: "/dashboard/wiki",
    icon: <AutoStoriesIcon sx={{ color: "#637381" }} />,
    text: "Wiki",
  },
];

export const ADMIN_ITEMS = [
  {
    to: "/dashboard/operations",
    icon: <ConstructionIcon />,
    text: "Admin Operations",
  },
  {
    to: "/dashboard/market-place-admin",
    icon: <ConstructionIcon />,
    text: "Admin Marketplace",
  },
];
