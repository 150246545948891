import React from 'react';
import { Typography, Box, Chip, Link } from '@mui/material';
import { styled } from '@mui/system';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LaunchIcon from '@mui/icons-material/Launch';

const StatusChip = styled(Chip)(() => ({
  backgroundColor: '#e0f7fa',
  color: '#00796b',
}));

const renderHTMLContent = (htmlContent) => {
  return { __html: htmlContent };
};

const DataDisplay = ({ item }) => {
  return (
    <Box
      sx={{
        border: '1px solid black',
        padding: '16px',
        marginBottom: '16px',
        backgroundColor: 'white',
        borderRadius: '8px',
      }}
    >
      <Typography
        variant="h6"
        component="div"
        gutterBottom
        sx={{
          fontWeight: '600',
          color: '#1C252E',
          fontFamily: "'Poppins', sans-serif",
        }}
      >
        {item.pcn_title}{' '}
        <StatusChip
          label={
            item.is_radiation_related
              ? 'Radiation Related'
              : 'Non-Radiation Related'
          }
        />
      </Typography>

      <Box
        sx={{ display: 'flex', gap: '20px', marginTop: 2, marginBottom: '8px' }}
      >
        <Typography
          sx={{
            fontSize: 14,
            fontFamily: "'Poppins', sans-serif",
            fontWeight: 600,
            display: 'flex',
            gap: '5px',
            minWidth: '110px',
            textAlign: 'left',
          }}
          component="th"
        >
          Publication Date: :
        </Typography>
        <Typography
          sx={{
            fontSize: 14,
            fontFamily: "'Poppins', sans-serif",
            fontWeight: 400,
            color: '#637381',
            display: 'flex',
          }}
        >
          {item.publication_date}
        </Typography>
      </Box>

      <Box sx={{ display: 'flex', gap: '20px', marginBottom: '8px' }}>
        <Typography
          sx={{
            fontSize: 14,
            fontFamily: "'Poppins', sans-serif",
            fontWeight: 600,
            display: 'flex',
            gap: '5px',
            minWidth: '110px',
            textAlign: 'left',
          }}
          component="th"
        >
          Effectivity Date:
        </Typography>
        <Typography
          sx={{
            fontSize: 14,
            fontFamily: "'Poppins', sans-serif",
            fontWeight: 400,
            color: '#637381',
            display: 'flex',
          }}
        >
          {item.effectivity_date} (the earliest date that a customer could
          expect to receive changed material)
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', gap: '20px', marginBottom: '8px' }}>
        <Typography
          sx={{
            fontSize: 14,
            fontFamily: "'Poppins', sans-serif",
            fontWeight: 600,
            display: 'flex',
            gap: '5px',
            minWidth: '110px',
            textAlign: 'left',
          }}
          component="th"
        >
          Revision Description:
        </Typography>
        <Typography
          sx={{
            fontSize: 14,
            fontFamily: "'Poppins', sans-serif",
            fontWeight: 400,
            color: '#637381',
            display: 'flex',
          }}
        >
          {item.revision_description}
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', gap: '20px', marginBottom: '8px' }}>
        <Typography
          sx={{
            fontSize: 14,
            fontFamily: "'Poppins', sans-serif",
            fontWeight: 600,
            display: 'flex',
            gap: '5px',
            minWidth: '110px',
            textAlign: 'left',
          }}
          component="th"
        >
          Pdf:
        </Typography>
        <Link href={item.file_url} target="__blank">
          <Typography
            component="div"
            sx={{
              fontSize: 14,
              fontFamily: "'Poppins', sans-serif",
              fontWeight: 400,
              color: '#637381',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            PCN_0{item.id} <LaunchIcon fontSize="small" />
          </Typography>
        </Link>
      </Box>
      <Box>
        <Typography
          sx={{
            fontSize: 14,
            fontFamily: "'Poppins', sans-serif",
            fontWeight: 600,
            display: 'flex',
            gap: '5px',
            minWidth: '110px',
            textAlign: 'left',
          }}
          component="th"
        >
          Description Of Change:
        </Typography>
        <Box
          sx={{
            fontSize: 14,
            fontFamily: "'Poppins', sans-serif",
            marginBottom: '8px',
            '& p': {
              color: '#637381',
              fontSize: 14,
            },
            '& table': {
              width: '100%',
              borderCollapse: 'collapse',
              marginBottom: '16px',
            },
            '& th, & td': {
              border: '1px solid black',
              padding: '8px',
            },
            '& tr:nth-of-type(odd)': {
              backgroundColor: '#f9f9f9',
            },
          }}
          dangerouslySetInnerHTML={renderHTMLContent(
            item.description_of_change
          )}
        />
      </Box>

      <Box>
        <Typography
          sx={{
            fontSize: 14,
            fontFamily: "'Poppins', sans-serif",
            fontWeight: 600,
            display: 'flex',
            gap: '5px',
            minWidth: '110px',
            textAlign: 'left',
          }}
          component="th"
        >
          Reason For Change:
        </Typography>
        <Box
          style={{ fontFamily: "'Poppins', sans-serif", color: '#637381' }}
          dangerouslySetInnerHTML={renderHTMLContent(item.reason_for_change)}
        />
      </Box>
    </Box>
  );
};

const PartChangeNotification = ({ pcn_updates }) => {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <Box
      sx={{
        margin: 2,
        backgroundColor: 'white',
        boxShadow:
          'rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px',
        borderRadius: '16px',
        padding: '16px 16px 16px 24px',
      }}
    >
      <Typography
        variant="h6"
        component="div"
        gutterBottom
        sx={{
          marginBottom: 5,
          fontWeight: '600',
          color: '#1C252E',
          fontFamily: "'Poppins', sans-serif",
        }}
      >
        Part change notifications
      </Typography>
      {pcn_updates?.map((item) => (
        <Accordion
          style={{ boxShadow: 'none' }}
          key={item.id}
          onChange={handleChange(item.id)}
          expanded={expanded === item.id}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`${item.id}bh-content`}
            id={`${item.id}bh-header`}
          >
            <Box>
              <Typography
                variant="body1"
                sx={{
                  marginBottom: '8px',
                  fontSize: 18,
                  fontFamily: "'Poppins', sans-serif",
                  fontWeight: 600,
                }}
              >
                {item.pcn_title}
              </Typography>
              <Typography
                component="div"
                sx={{
                  fontSize: 14,
                  fontFamily: "'Poppins', sans-serif",
                  fontWeight: 400,
                  color: '#637381',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                {item.publication_date}
              </Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <DataDisplay item={item} />
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  );
};

export default PartChangeNotification;