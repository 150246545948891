import React from 'react';
import { Fab, Box, useScrollTrigger, Zoom } from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

const ScrollTop = (props) => {
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <Zoom in={trigger}>
      <Box
        onClick={handleClick}
        role="presentation"
        sx={{ position: 'fixed', bottom: 16, right: 16, zIndex: 1000 }}
      >
        {props.children}
      </Box>
    </Zoom>
  );
};

const FloatingActionButton = () => {
  return (
    <ScrollTop>
      <Fab id='homepage-floating-action-button' sx={{ backgroundColor: "#F26E21", borderRadius: "10px", color: "white", '&:hover': { backgroundColor: '#d65b1c' } }} size="small" aria-label="scroll back to top">
        <KeyboardArrowUpIcon />
      </Fab>
    </ScrollTop>
  );
};

export default FloatingActionButton;