import React, { useState } from 'react';
import {
  Typography,
  Grid,
  TextField,
  Box,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
} from '@mui/material';
import SectionHeader from '../SectionHeader/section-header';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider as MuiLocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import AnimateButton from '../Button/AnimateButton';
import { CONTACT_INFO } from '../../constants/about-us';

const RadiationIntake = () => {
  const textFieldStyles = {
    svg: {
      color: '#3B3664',
    },
    '& .MuiOutlinedInput-root': {
      color: 'black',
      '& fieldset': {
        border: 0,
        borderBottom: '1px solid gray',
        borderRadius: 0,
      },
      '&:hover fieldset': {
        border: 0,
        borderBottom: '1px solid gray',
        borderRadius: 0,
      },
      '&.Mui-focused fieldset': {
        border: 0,
        borderBottom: '1px solid gray',
        borderRadius: 0,
      },
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 0,
    },
  };

  const labelStyles = {
    color: '#3B3664',
    fontFamily: "'Poppins', sans-serif",
    '&.Mui-focused': {
      color: '#3B3664',
    },
  };

  const selectStyles = {
    '& .MuiOutlinedInput-notchedOutline': {
      border: 0,
      borderRadius: 0,
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: 'transparent',
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: 'transparent',
    },
    '& .MuiSelect-outlined': {
      borderRadius: 0,
      border: 0,
      borderBottom: '1px solid gray',
    },
    '& .MuiInputLabel-outlined.Mui-focused': {
      color: '#3B3664',
      fontFamily: "'Poppins', sans-serif",
    },
    '& .MuiOutlinedInput-root': {
      textAlign: 'start',
      '&:hover': {
        '& fieldset': {
          borderColor: '#3B3664',
        },
      },
      '&.Mui-focused': {
        '& fieldset': {
          borderColor: '#3B3664',
        },
      },
    },
  };

  const [formData, setFormData] = useState({
    name: '',
    company: '',
    email: '',
    parts: '',
    Manufacturer: '',
    Qty: '',
    Radiation: '',
    Deadline: null,
    PublicData: '',
    Decapsulation: '',
    message: '',
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleDateChange = (date) => {
    setFormData((prevState) => ({
      ...prevState,
      Deadline: date,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const submissionData = {
      ...formData,
      Deadline: formData.Deadline
        ? formData.Deadline.format('YYYY-MM-DD')
        : null,
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/public/submit_testing_form`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(submissionData),
        }
      );
      const responseData = await response.json();
      if (response.ok) {
        alert('Form submitted successfully!');
        console.log('Server Response:', responseData);
      } else {
        throw new Error(responseData.message || 'Something went wrong');
      }
    } catch (error) {
      console.error('Error:', error);
      alert(`Error: ${error.message}`);
    }
  };

  return (
    <Box
      id="radiation-intake-form"
      sx={{
        mt: 5,
        color: 'black',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        marginBottom: '100px',
      }}
    >
      <SectionHeader
        title="Intake Form"
        subtitle="Fill out the form below to get started."
      />

      <Box
        sx={{
          alignItems: 'flex-start',
          display: 'flex',
          justifyContent: 'flex-start',
          gap: 5,
          mt: 5,
          px: 2,
          '@media (max-width:1270px)': {
            justifyContent: 'flex-start',
            flexDirection: 'column',
          },
          '@media (max-width:880px)': {
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            flexDirection: 'column',
          },
        }}
      >
        <Box
          sx={{
            position: 'relative',
            display: 'flex',
            backgroundColor: '#ffffff',
            boxShadow: '0px 10px 30px rgba(214, 215, 216, 0.57)',
            padding: '50px 37px',

            borderRadius: '8px',
            width: '100%',
            maxWidth: { md: '100%', lg: '350px' },
            zIndex: 2,
            '&::before': {
              content: '""',
              position: 'absolute',
              top: '-12px',
              left: '-12px',
              width: '100%',
              height: '100%',
              backgroundColor: '#ffffff',
              boxShadow: '0px 10px 30px rgba(214, 215, 216, 0.57)',
              borderRadius: '8px',
              zIndex: -1,
            },
          }}
        >
          <Box sx={{ width: '98%' }}>
            {CONTACT_INFO.map((info, index) => (
              <React.Fragment key={info.title}>
                <Box sx={{ display: 'flex', mb: 3 }}>
                  <Box sx={{ mr: 2 }}>{info.icon}</Box>
                  <Box sx={{ textAlign: 'left' }}>
                    <Typography
                      variant="h6"
                      sx={{
                        fontWeight: 600,
                        fontFamily: "'Poppins', sans-serif",
                      }}
                      gutterBottom
                    >
                      {info.title}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: '15px',
                        fontFamily: "'Poppins', sans-serif",
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                      }}
                      variant="body1"
                      color="textSecondary"
                    >
                      {info.content.split('\n').map((line, i) => (
                        <span key={i}>
                          {line}
                          <br />
                        </span>
                      ))}
                    </Typography>
                  </Box>
                </Box>
                {index < CONTACT_INFO.length - 1 && (
                  <Box
                    sx={{
                      borderTop: '2px dotted rgba(0, 0, 0, 0.2)',
                      my: 3,
                    }}
                  />
                )}
              </React.Fragment>
            ))}
          </Box>
        </Box>
        <form onSubmit={handleSubmit}>
          <Grid
            item
            xs={12}
            md={8}
            lg={8}
            sx={{ maxWidth: { sm: '95%', md: '1280px', lg: '1580px' } }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  InputLabelProps={{
                    style: { color: '#3B3664' },
                    fontFamily: "'Poppins', sans-serif",
                  }}
                  required
                  label="Name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  fullWidth
                  variant="outlined"
                  sx={textFieldStyles}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  InputLabelProps={{
                    style: { color: '#3B3664' },
                    fontFamily: "'Poppins', sans-serif",
                  }}
                  label="Email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                  fullWidth
                  variant="outlined"
                  sx={textFieldStyles}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  InputLabelProps={{
                    style: { color: '#3B3664' },
                    fontFamily: "'Poppins', sans-serif",
                  }}
                  label="Part No."
                  name="parts"
                  value={formData.parts}
                  onChange={handleChange}
                  fullWidth
                  variant="outlined"
                  sx={textFieldStyles}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  InputLabelProps={{
                    style: { color: '#3B3664' },
                    fontFamily: "'Poppins', sans-serif",
                  }}
                  label="Manufacturer"
                  name="Manufacturer"
                  value={formData.Manufacturer}
                  onChange={handleChange}
                  fullWidth
                  variant="outlined"
                  sx={textFieldStyles}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  InputLabelProps={{
                    style: { color: '#3B3664' },
                    fontFamily: "'Poppins', sans-serif",
                  }}
                  label="Quantity Per Part Number"
                  name="Qty"
                  value={formData.Qty}
                  onChange={handleChange}
                  fullWidth
                  variant="outlined"
                  sx={textFieldStyles}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  InputLabelProps={{
                    style: { color: '#3B3664' },
                    fontFamily: "'Poppins', sans-serif",
                  }}
                  label="Radiation Effects Test to be done"
                  name="Radiation"
                  value={formData.Radiation}
                  onChange={handleChange}
                  fullWidth
                  variant="outlined"
                  sx={textFieldStyles}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <FormControl fullWidth>
                  <InputLabel sx={labelStyles}>
                    Do you want a discount to make data public?
                  </InputLabel>
                  <Select
                    name="PublicData"
                    value={formData.PublicData}
                    onChange={handleChange}
                    variant="outlined"
                    sx={selectStyles}
                    label="Do you want a discount to make data public?"
                  >
                    <MenuItem value="Yes">
                      <Typography
                        sx={{ fontFamily: "'Poppins', sans-serif" }}
                        align="left"
                        variant="body1"
                      >
                        Yes
                      </Typography>
                    </MenuItem>
                    <MenuItem value="No">
                      <Typography
                        sx={{ fontFamily: "'Poppins', sans-serif" }}
                        align="left"
                        variant="body1"
                      >
                        No
                      </Typography>
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <FormControl fullWidth>
                  <InputLabel sx={labelStyles}>
                    De-capsulation needed?
                  </InputLabel>
                  <Select
                    name="Decapsulation"
                    value={formData.Decapsulation}
                    onChange={handleChange}
                    variant="outlined"
                    sx={selectStyles}
                    label="De-capsulation needed?"
                  >
                    <MenuItem value="Yes">
                      <Typography
                        sx={{ fontFamily: "'Poppins', sans-serif" }}
                        align="left"
                        variant="body1"
                      >
                        Yes
                      </Typography>
                    </MenuItem>
                    <MenuItem value="No">
                      <Typography
                        sx={{ fontFamily: "'Poppins', sans-serif" }}
                        align="left"
                        variant="body1"
                      >
                        No
                      </Typography>
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <MuiLocalizationProvider dateAdapter={AdapterDayjs}>
                <Grid item xs={12}>
                  <DatePicker
                    slotProps={{
                      textField: {
                        InputLabelProps: {
                          style: { color: '#3B3664' },
                          fontFamily: "'Poppins', sans-serif",
                        },
                        fullWidth: true,
                        variant: 'outlined',
                        sx: textFieldStyles,
                      },
                    }}
                    label="Deadline for test completion"
                    value={formData.Deadline}
                    onChange={handleDateChange}
                    renderInput={(props) => (
                      <TextField
                        {...props}
                        InputLabelProps={{
                          style: { color: '#3B3664' },
                          fontFamily: "'Poppins', sans-serif",
                        }}
                        fullWidth
                        variant="outlined"
                        sx={textFieldStyles}
                      />
                    )}
                  />
                </Grid>
              </MuiLocalizationProvider>
              <Grid item xs={12}>
                <TextField
                  InputLabelProps={{
                    style: { color: '#3B3664' },
                    fontFamily: "'Poppins', sans-serif",
                  }}
                  label="Instructions"
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  required
                  fullWidth
                  multiline
                  rows={3}
                  variant="outlined"
                  sx={textFieldStyles}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sx={{
                  display: 'flex',
                  justifyContent: 'start',
                  alignItems: 'start',
                }}
              >
                <AnimateButton type="submit">Send Message</AnimateButton>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Box>
  );
};

export default RadiationIntake;