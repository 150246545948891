import React, { useState, useEffect } from 'react';
import { TextField, Button, Autocomplete, Box } from '@mui/material';
import { sanitizeInput } from '../../helper/helper';

const AddAliasForm = () => {
  const [manufacturers, setManufacturers] = useState([]);
  const [selectedManufacturer, setSelectedManufacturer] = useState(null);
  const [alias, setAlias] = useState('');

  useEffect(() => {
    const fetchManufacturers = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/operations/manufacturers`, {
          method: 'post',
          credentials: 'include'
        });
        const data = await response.json();
        if (response.ok) {
          setManufacturers(data);
        } else {
          throw new Error('Failed to fetch manufacturers');
        }
      } catch (error) {
        console.log({ type: 'error', text: error.message });
      }
    };

    fetchManufacturers();
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!selectedManufacturer) {
      console.log('Please select a manufacturer');
      return;
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/operations/add-mfg-alias`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        credentials: 'include',
        body: JSON.stringify({
          manufacturerName: selectedManufacturer.name,
          aliasName: alias
        })
      });

      if (response.ok) {
        console.log('Alias added successfully');
        // Optionally, you can reset the form here or show a success message
      } else {
        throw new Error('Failed to add alias');
      }
    } catch (error) {
      console.log({ type: 'error', text: error.message });
    }
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      id='add-alias-form'
    >
      <Autocomplete
        id="manufacturer-autocomplete"
        options={manufacturers}
        getOptionLabel={(option) => option.name}
        renderInput={(params) => (
          <TextField
            style={{
              width: "100%",
              marginRight: "0",
              marginBottom: "20px",
            }}
            {...params}
            label="Manufacturer"
            variant="outlined"
            sx={{
              "& .MuiOutlinedInput-root": {
                borderRadius: 2,
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "black", // Set focus border color to black
                },
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: "rgba(145, 158, 171, 0.8)", // Example hover effect with 0.8 opacity
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderRadius: 2,
                borderColor: "rgba(145, 158, 171, 0.2)",
              },
              "& .MuiInputLabel-root": {
                color: "rgba(145 158 171)", // Initial label color
                "&.Mui-focused": {
                  color: "black", // Label color on focus
                },
              },
              "& .MuiInputBase-input::placeholder": {
                color: "rgba(145, 158, 171, 0.8)", // Placeholder text color
                opacity: 1, // Ensure full opacity
              },
              "& .MuiInputBase-input:focus::placeholder": {
                color: "rgba(145, 158, 171, 0.8)", // Placeholder text color on focus
              },
            }}
          />
          // <TextField
          //     {...params}
          //     label="Manufacturer"
          //     variant="outlined"
          //     sx={{
          //         '& .MuiInputBase-root': {
          //             backgroundColor: 'white', // Background color of the root input
          //             color: 'black' // Text color inside the input
          //         },
          //         '& .MuiInputBase-input': {
          //             backgroundColor: 'white', // Background color inside the input
          //             color: 'black' // Text color inside the input
          //         },
          //         '& .MuiOutlinedInput-notchedOutline': {
          //             borderColor: 'black' // Border color
          //         },
          //         '& .MuiInputLabel-root': {
          //             color: 'black' // Label color
          //         },
          //         '& .MuiFormLabel-root': {
          //             color: 'black' // Label color
          //         },
          //         '& .MuiInput-underline:before': {
          //             borderBottomColor: 'white' // Bottom border color
          //         },
          //         '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
          //             borderBottomColor: 'white' // Bottom border color on hover
          //         }
          //     }}
          // />
        )}
        value={selectedManufacturer}
        onChange={(event, newValue) => setSelectedManufacturer(newValue)}
      />

      {/* <TextField
                label="Alias"
                value={alias}
                onChange={(e) => setAlias(e.target.value)}
                variant="outlined"
                sx={{
                    '& .MuiInputBase-root': {
                        backgroundColor: 'white', // Background color of the root input
                        color: 'black' // Text color inside the input
                    },
                    '& .MuiInputBase-input': {
                        backgroundColor: 'white', // Background color inside the input
                        color: 'black' // Text color inside the input
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'black' // Border color
                    },
                    '& .MuiInputLabel-root': {
                        color: 'black' // Label color
                    },
                    '& .MuiFormLabel-root': {
                        color: 'black' // Label color
                    },
                    '& .MuiInput-underline:before': {
                        borderBottomColor: 'white' // Bottom border color
                    },
                    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
                        borderBottomColor: 'white' // Bottom border color on hover
                    }
                }}
            /> */}
      {/* <Button
                variant="contained"
                color="primary"
                type="submit"
                sx={{
                    backgroundColor: 'blue',
                    color: 'white',
                    '&:hover': {
                        backgroundColor: 'darkblue'
                    }
                }}
            >
                Add Alias
            </Button> */}










      <TextField
        id="alias-input"
        style={{
          width: "100%",
          marginRight: "0",
          marginBottom: "10px",
        }}
        label="Alias"
        value={alias}
        onChange={(e) => setAlias(sanitizeInput(e.target.value))}
        variant="outlined"
        sx={{
          "& .MuiOutlinedInput-root": {
            borderRadius: 2,
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: "black", // Set focus border color to black
            },
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "rgba(145, 158, 171, 0.8)", // Example hover effect with 0.8 opacity
          },
          "& .MuiOutlinedInput-notchedOutline": {
            borderRadius: 2,
            borderColor: "rgba(145, 158, 171, 0.2)",
          },
          "& .MuiInputLabel-root": {
            color: "rgba(145 158 171)", // Initial label color
            "&.Mui-focused": {
              color: "black", // Label color on focus
            },
          },
          "& .MuiInputBase-input::placeholder": {
            color: "rgba(145, 158, 171, 0.8)", // Placeholder text color
            opacity: 1, // Ensure full opacity
          },
          "& .MuiInputBase-input:focus::placeholder": {
            color: "rgba(145, 158, 171, 0.8)", // Placeholder text color on focus
          },
        }}
      />

      <Box sx={{ display: "flex", justifyContent: "end", alignItems: "end" }}>
        <Button
          type="submit"
          sx={{
            mt: 3,
            mb: 1,
            borderRadius: "12px",
            backgroundColor: "#F26E21",
            color: "#fff",
            textAlign: "center",
            fontSize: "14px",

            px: 2,
            py: 2,
            minWidth: 200,
            height: "48px",
            cursor: "pointer",
            fontFamily: "'Poppins', sans-serif",
            textTransform: "capitalize",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            "&:hover": {
              backgroundColor: "#F26E21", // Keep the same background on hover
              boxShadow: "none", // Remove box-shadow on hover if any
            },
            "&:active": {
              backgroundColor: "#F26E21", // Keep the same background on click
              boxShadow: "none", // Remove box-shadow on click if any
            },
            "&.Mui-focusVisible": {
              backgroundColor: "#F26E21", // Keep the same background on focus
              boxShadow: "none", // Remove box-shadow on focus if any
            },
          }}
        >
          Add Alias
        </Button>
      </Box>
    </Box>
  );
};

export default AddAliasForm;
