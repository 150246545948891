import React, { useState, useEffect } from 'react';
import { TextField, Button, Select, MenuItem, Box, Autocomplete, FormControlLabel, Checkbox } from '@mui/material';
import optionsTestType from '../Admin/optionsTestType';
import useFetchTestResult from '../../hooks/useFetchTestResult';
import useFetchMPNs from '../../hooks/useFetchMPNs';
import useFetchDataSources from '../../hooks/useFetchDataSources';
import useEditTestResult from '../../hooks/useEditTestResult';
import { sanitizeInput } from '../../helper/helper';

const EditTestResultForm = ({ testResultName, onClose }) => {
    const [formData, setFormData] = useState({
        name: '',
        test_type: '',
        description: '',
        summary: '',
        restricted: false,
        restrictedDescription: '',
        hidden: false,
    });

    const fetchedTestResult = useFetchTestResult(testResultName);
    const dataSources = useFetchDataSources();
    const [selectedDataSource, setSelectedDataSource] = useState(null);
    const mpns = useFetchMPNs();
    const [selectedMPN, setSelectedMPN] = useState(null);
    const { editTestResult, statusMessage } = useEditTestResult();

    useEffect(() => {
        if (fetchedTestResult) {
            setFormData({
                name: fetchedTestResult.name || '',
                test_type: fetchedTestResult.test_type || '',
                description: fetchedTestResult.description || '',
                summary: fetchedTestResult.summary || '',
                restricted: fetchedTestResult.restricted ? 1 : 0,
                restrictedDescription: fetchedTestResult.restrictedDescription || '',
                hidden: fetchedTestResult.hidden ? 1: 0,
            });

            if (fetchedTestResult.selectedDataSource) {
                setSelectedDataSource({
                    title: fetchedTestResult.selectedDataSource,
                });
            } else {
                setSelectedDataSource(null);
            }

            if (fetchedTestResult.selectedMPN) {
                setSelectedMPN({
                    name: fetchedTestResult.selectedMPN,
                });
            } else {
                setSelectedMPN(null);
            }
        }
    }, [fetchedTestResult]);

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: type === 'checkbox' ? sanitizeInput(checked) : sanitizeInput(value)
        }));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        const payload = {
            name: formData.name,
            test_type: formData.test_type,
            description: formData.description,
            summary: formData.summary,
            restricted: formData.restricted,
            restrictedDescription: formData.restrictedDescription,
            selectedMPN: selectedMPN,
            selectedDataSource: selectedDataSource,
            hidden: formData.hidden
        };

        await editTestResult(payload, onClose);
    };

    return (
        <Box
            component="form"
            onSubmit={handleSubmit}
            sx={{
                // display: 'flex',
                // flexDirection: 'column',
                // gap: 3,
                // width: '100%',
                // maxWidth: 400,
                // p: 3,
                // borderRadius: 1,
                // boxShadow: 2,
                // bgcolor: 'background.paper',

                backgroundColor: "white",
                boxShadow:
                  "rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px",
                alignItems: "center",
                borderRadius: "16px",
                padding: "24px 16px 16px 24px",

            }}
        >
            <TextField
                label="Test Result Name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                fullWidth
                sx={{
                    mb:3,
                    "& .MuiOutlinedInput-root": {
                      borderRadius: 2,
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "black", // Set focus border color to black
                      },
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(145, 158, 171, 0.8)", // Example hover effect with 0.8 opacity
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderRadius: 2,
                      borderColor: "rgba(145, 158, 171, 0.2)",
                    },
                    "& .MuiInputLabel-root": {
                      color: "rgba(145 158 171)", // Initial label color
                      "&.Mui-focused": {
                        color: "black", // Label color on focus
                      },
                    },
                    "& .MuiInputBase-input::placeholder": {
                      color: "rgba(145, 158, 171, 0.8)", // Placeholder text color
                      opacity: 1, // Ensure full opacity
                    },
                    "& .MuiInputBase-input:focus::placeholder": {
                      color: "rgba(145, 158, 171, 0.8)", // Placeholder text color on focus
                    },
                  }}
            />

            <Select
                label="Test Type"
                id="datasources-test-type-dropdown"
                name="test_type"
                value={formData.test_type}
                onChange={handleChange}
                fullWidth
                sx={{
                    mb:3,
                    "& .MuiOutlinedInput-root": {
                      borderRadius: 2,
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "black", // Set focus border color to black
                      },
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(145, 158, 171, 0.8)", // Example hover effect with 0.8 opacity
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderRadius: 2,
                      borderColor: "rgba(145, 158, 171, 0.2)",
                    },
                    "& .MuiInputLabel-root": {
                      color: "rgba(145 158 171)", // Initial label color
                      "&.Mui-focused": {
                        color: "black", // Label color on focus
                      },
                    },
                    "& .MuiInputBase-input::placeholder": {
                      color: "rgba(145, 158, 171, 0.8)", // Placeholder text color
                      opacity: 1, // Ensure full opacity
                    },
                    "& .MuiInputBase-input:focus::placeholder": {
                      color: "rgba(145, 158, 171, 0.8)", // Placeholder text color on focus
                    },
                  }}
            >
                {optionsTestType.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                        {option.label}
                    </MenuItem>
                ))}
            </Select>


<Box sx={{display:"flex",justifyContent:"center",alignItems:"center",gap:"20px"}} >

<Autocomplete
                options={dataSources}
                getOptionLabel={(option) => option.title}
                renderInput={(params) => <TextField {...params} label="Data Sources" />}
                value={selectedDataSource}
                onChange={(event, newValue) => setSelectedDataSource(newValue)}
                isOptionEqualToValue={(option, value) => option.title === value.title}  // Custom equality check
                fullWidth
                sx={{
                    mb:3,
                    "& .MuiOutlinedInput-root": {
                      borderRadius: 2,
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "black", // Set focus border color to black
                      },
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(145, 158, 171, 0.8)", // Example hover effect with 0.8 opacity
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderRadius: 2,
                      borderColor: "rgba(145, 158, 171, 0.2)",
                    },
                    "& .MuiInputLabel-root": {
                      color: "rgba(145 158 171)", // Initial label color
                      "&.Mui-focused": {
                        color: "black", // Label color on focus
                      },
                    },
                    "& .MuiInputBase-input::placeholder": {
                      color: "rgba(145, 158, 171, 0.8)", // Placeholder text color
                      opacity: 1, // Ensure full opacity
                    },
                    "& .MuiInputBase-input:focus::placeholder": {
                      color: "rgba(145, 158, 171, 0.8)", // Placeholder text color on focus
                    },
                  }}
            />

            <Autocomplete
                options={mpns}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => <TextField {...params} label="MPN" />}
                value={selectedMPN}
                onChange={(event, newValue) => setSelectedMPN(newValue)}
                isOptionEqualToValue={(option, value) => option.name === value.name} 
                fullWidth
                sx={{
                    mb:3,
                    "& .MuiOutlinedInput-root": {
                      borderRadius: 2,
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "black", // Set focus border color to black
                      },
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(145, 158, 171, 0.8)", // Example hover effect with 0.8 opacity
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderRadius: 2,
                      borderColor: "rgba(145, 158, 171, 0.2)",
                    },
                    "& .MuiInputLabel-root": {
                      color: "rgba(145 158 171)", // Initial label color
                      "&.Mui-focused": {
                        color: "black", // Label color on focus
                      },
                    },
                    "& .MuiInputBase-input::placeholder": {
                      color: "rgba(145, 158, 171, 0.8)", // Placeholder text color
                      opacity: 1, // Ensure full opacity
                    },
                    "& .MuiInputBase-input:focus::placeholder": {
                      color: "rgba(145, 158, 171, 0.8)", // Placeholder text color on focus
                    },
                  }}
            />


</Box>

            
            <TextField
                label="Description"
                name="description"
                value={formData.description}
                onChange={handleChange}
                multiline
                rows={3}
                fullWidth
                sx={{
                    mb:3,
                    "& .MuiOutlinedInput-root": {
                      borderRadius: 2,
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "black", // Set focus border color to black
                      },
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(145, 158, 171, 0.8)", // Example hover effect with 0.8 opacity
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderRadius: 2,
                      borderColor: "rgba(145, 158, 171, 0.2)",
                    },
                    "& .MuiInputLabel-root": {
                      color: "rgba(145 158 171)", // Initial label color
                      "&.Mui-focused": {
                        color: "black", // Label color on focus
                      },
                    },
                    "& .MuiInputBase-input::placeholder": {
                      color: "rgba(145, 158, 171, 0.8)", // Placeholder text color
                      opacity: 1, // Ensure full opacity
                    },
                    "& .MuiInputBase-input:focus::placeholder": {
                      color: "rgba(145, 158, 171, 0.8)", // Placeholder text color on focus
                    },
                  }}
            />

            <TextField
                label="Summary"
                name="summary"
                value={formData.summary}
                onChange={handleChange}
                multiline
                rows={3}
                fullWidth
                sx={{
                    mb:3,
                    "& .MuiOutlinedInput-root": {
                      borderRadius: 2,
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "black", // Set focus border color to black
                      },
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(145, 158, 171, 0.8)", // Example hover effect with 0.8 opacity
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderRadius: 2,
                      borderColor: "rgba(145, 158, 171, 0.2)",
                    },
                    "& .MuiInputLabel-root": {
                      color: "rgba(145 158 171)", // Initial label color
                      "&.Mui-focused": {
                        color: "black", // Label color on focus
                      },
                    },
                    "& .MuiInputBase-input::placeholder": {
                      color: "rgba(145, 158, 171, 0.8)", // Placeholder text color
                      opacity: 1, // Ensure full opacity
                    },
                    "& .MuiInputBase-input:focus::placeholder": {
                      color: "rgba(145, 158, 171, 0.8)", // Placeholder text color on focus
                    },
                  }}
            />
<Box sx={{display:"flex",justifyContent:"flex-start",alignItems:"center",gap:"20px"}} > 
            <FormControlLabel
                control={
                    <Checkbox
                        checked={formData.restricted}
                        onChange={handleChange}
                        name="restricted"
                        color="primary"
                        sx={{
                            // mb:3,
                            "& .MuiOutlinedInput-root": {
                              borderRadius: 2,
                              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                borderColor: "black", // Set focus border color to black
                              },
                            },
                            "&:hover .MuiOutlinedInput-notchedOutline": {
                              borderColor: "rgba(145, 158, 171, 0.8)", // Example hover effect with 0.8 opacity
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderRadius: 2,
                              borderColor: "rgba(145, 158, 171, 0.2)",
                            },
                            "& .MuiInputLabel-root": {
                              color: "rgba(145 158 171)", // Initial label color
                              "&.Mui-focused": {
                                color: "black", // Label color on focus
                              },
                            },
                            "& .MuiInputBase-input::placeholder": {
                              color: "rgba(145, 158, 171, 0.8)", // Placeholder text color
                              opacity: 1, // Ensure full opacity
                            },
                            "& .MuiInputBase-input:focus::placeholder": {
                              color: "rgba(145, 158, 171, 0.8)", // Placeholder text color on focus
                            },
                          }}
                    />
                }
                label="Restricted"
            />

            {formData.restricted && (
                <TextField
                    label="Restricted Description"
                    name="restrictedDescription"
                    value={formData.restrictedDescription}
                    onChange={handleChange}
                    multiline
                    rows={1}
                    fullWidth
                    sx={{
                        // mb:3,
                        "& .MuiOutlinedInput-root": {
                          borderRadius: 2,
                          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            borderColor: "black", // Set focus border color to black
                          },
                        },
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                          borderColor: "rgba(145, 158, 171, 0.8)", // Example hover effect with 0.8 opacity
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderRadius: 2,
                          borderColor: "rgba(145, 158, 171, 0.2)",
                        },
                        "& .MuiInputLabel-root": {
                          color: "rgba(145 158 171)", // Initial label color
                          "&.Mui-focused": {
                            color: "black", // Label color on focus
                          },
                        },
                        "& .MuiInputBase-input::placeholder": {
                          color: "rgba(145, 158, 171, 0.8)", // Placeholder text color
                          opacity: 1, // Ensure full opacity
                        },
                        "& .MuiInputBase-input:focus::placeholder": {
                          color: "rgba(145, 158, 171, 0.8)", // Placeholder text color on focus
                        },
                      }}
                />
            )}
</Box>
            <FormControlLabel
                control={
                    <Checkbox
                        checked={formData.hidden}
                        onChange={handleChange}
                        name="hidden"
                        color="primary"
                        sx={{
                            // mb:3,
                            
                            "& .MuiOutlinedInput-root": {
                              borderRadius: 2,
                              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                borderColor: "black", // Set focus border color to black
                              },
                            },
                            "&:hover .MuiOutlinedInput-notchedOutline": {
                              borderColor: "rgba(145, 158, 171, 0.8)", // Example hover effect with 0.8 opacity
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderRadius: 2,
                              borderColor: "rgba(145, 158, 171, 0.2)",
                            },
                            "& .MuiInputLabel-root": {
                              color: "rgba(145 158 171)", // Initial label color
                              "&.Mui-focused": {
                                color: "black", // Label color on focus
                              },
                            },
                            "& .MuiInputBase-input::placeholder": {
                              color: "rgba(145, 158, 171, 0.8)", // Placeholder text color
                              opacity: 1, // Ensure full opacity
                            },
                            "& .MuiInputBase-input:focus::placeholder": {
                              color: "rgba(145, 158, 171, 0.8)", // Placeholder text color on focus
                            },
                          }}
                    />
                }
                label="Hidden"
            />

            {/* <Button variant="contained" color="primary" type="submit" fullWidth sx={{ mt: 2 }}>
                Submit
            </Button> */}


<Box sx={{ display: "flex", justifyContent: "end", alignItems: "end" }}>
        <Button
          type="submit"
          sx={{
            mt: 3,
            mb: 1,
            borderRadius: "12px",
            backgroundColor: "#F26E21",
            color: "#fff",
            textAlign: "center",
            fontSize: "14px",

            px: 2,
            py: 2,
            minWidth: 200,
            height: "48px",
            cursor: "pointer",
            fontFamily: "'Poppins', sans-serif",
            textTransform: "capitalize",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            "&:hover": {
              backgroundColor: "#F26E21", // Keep the same background on hover
              boxShadow: "none", // Remove box-shadow on hover if any
            },
            "&:active": {
              backgroundColor: "#F26E21", // Keep the same background on click
              boxShadow: "none", // Remove box-shadow on click if any
            },
            "&.Mui-focusVisible": {
              backgroundColor: "#F26E21", // Keep the same background on focus
              boxShadow: "none", // Remove box-shadow on focus if any
            },
          }}
        >
          Submit
        </Button>
      </Box>  



            {statusMessage && (
                <Box sx={{ color: statusMessage.type === 'success' ? 'green' : 'red', mt: 2 }}>
                    {statusMessage.text}
                </Box>
            )}
        </Box>
    );
};

export default EditTestResultForm;

