export const termsAndConditions = [
    {
        heading: "",
        content: "The website located at https://www.nucleonspace.com/ (the <b>“Site”</b>) is a copyrighted work belonging to Nucleon (Cyclo Technologies, Inc DBA Nucleon) (<b>“Company”</b>, <b>“us”</b>, <b>“our”</b>, and <b>“we”</b>). Certain features of the Site may be subject to additional guidelines, terms, or rules, which will be posted on the Site in connection with such features. All such additional terms, guidelines, and rules are incorporated by reference into these Terms. THESE TERMS OF USE (THESE <b>“TERMS”</b>) SET FORTH THE LEGALLY BINDING TERMS AND CONDITIONS THAT GOVERN YOUR USE OF THE SITE. BY ACCESSING OR USING THE SITE, YOU ARE ACCEPTING THESE TERMS (ON BEHALF OF YOURSELF OR THE ENTITY THAT YOU REPRESENT), AND YOU REPRESENT AND WARRANT THAT YOU HAVE THE RIGHT, AUTHORITY, AND CAPACITY TO ENTER INTO THESE TERMS (ON BEHALF OF YOURSELF OR THE ENTITY THAT YOU REPRESENT). YOU MAY NOT ACCESS OR USE THE SITE OR ACCEPT THE TERMS IF YOU ARE NOT AT LEAST 18 YEARS OLD. IF YOU DO NOT AGREE WITH ALL OF THE PROVISIONS OF THESE TERMS, DO NOT ACCESS AND/OR USE THE SITE.",
    },
    {
        heading: "",
        content: "<b>PLEASE BE AWARE THAT SECTION 10.2 OF THESE TERMS CONTAINS PROVISIONS GOVERNING HOW DISPUTES THAT YOU AND WE HAVE AGAINST EACH OTHER ARE RESOLVED, INCLUDING, WITHOUT LIMITATION, ANY DISPUTES THAT AROSE OR WERE ASSERTED PRIOR TO THE EFFECTIVE DATE OF YOUR ACCEPTANCE OF THESE TERMS. IN PARTICULAR, IT CONTAINS AN ARBITRATION AGREEMENT WHICH WILL, WITH LIMITED EXCEPTIONS, REQUIRE DISPUTES BETWEEN US TO BE SUBMITTED TO BINDING AND FINAL ARBITRATION. UNLESS YOU OPT OUT OF THE ARBITRATION AGREEMENT: (1) YOU WILL ONLY BE PERMITTED TO PURSUE DISPUTES OR CLAIMS AND SEEK RELIEF AGAINST US ON AN INDIVIDUAL BASIS, NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY CLASS OR REPRESENTATIVE ACTION OR PROCEEDING; AND (2) YOU ARE WAIVING YOUR RIGHT TO PURSUE DISPUTES OR CLAIMS AND SEEK RELIEF IN A COURT OF LAW AND TO HAVE A JURY TRIAL.</b>",
    },
    {
        heading: "1. Accounts",
        content: [
            {
                heading: "1.1 Account Creation",
                content: "In order to use certain features of the Site, you must register for an account (“Account”) and provide certain information about yourself as prompted by the account registration form. You represent and warrant that: (a) all required registration information you submit is truthful and accurate; (b) you will maintain the accuracy of such information. You may delete your Account at any time, for any reason, by following the instructions on the Site. Nucleon may suspend or terminate your Account in accordance with Section 8.",
            },
            {
                heading: "1.2 Account Responsibilities",
                content: "You are responsible for maintaining the confidentiality of your Account login information and are fully responsible for all activities that occur under your Account. You agree to immediately notify Nucleon of any unauthorized use, or suspected unauthorized use of your Account or any other breach of security. Nucleon cannot and will not be liable for any loss or damage arising from your failure to comply with the above requirements",
            },
        ],
    },
    {
        heading: "2. Access to the Site",
        content: [
            {
                heading: "2.1 License",
                content: "Subject to these Terms, Nucleon grants you a non-transferable, non-exclusive, revocable, limited license to use and access the Site for your internal engineering purposes. This license does not permit the use of the Site to develop or create a product or service that directly or indirectly competes with Nucleon",
            },
            {
                heading: "2.2 Certain Restrictions",
                content: "The rights granted to you in these Terms are subject to the following restrictions: (a) you shall not license, sell, rent, lease, transfer, assign, distribute, host, or otherwise commercially exploit the Site, whether in whole or in part, or any content displayed on the Site; (b) you shall not modify, make derivative works of, disassemble, reverse compile or reverse engineer any part of the Site; (c) you shall not access the Site in order to build a similar or competitive website, product, or service; and (d) except as expressly stated herein, no part of the Site may be copied, reproduced, distributed, republished, downloaded, displayed, posted or transmitted in any form or by any means. Unless otherwise indicated, any future release, update, or other addition to functionality of the Site shall be subject to these Terms. All copyright and other proprietary notices on the Site (or on any content displayed on the Site) must be retained on all copies thereof. You shall not programmatically download content from the Site without written permission. Nucleon will monitor for bulk downloads or scraping, and users who violate these terms will be subject to penalties.",
            },
            {
                heading: "2.3 Modification",
                content: "Nucleon reserves the right, at any time, to modify, suspend, or discontinue the Site (in whole or in part) with or without notice to you. You agree that Nucleon will not be liable to you or to any third party for any modification, suspension, or discontinuation of the Site or any part thereof.",
            },
            {
                heading: "2.4 No Support or Maintenance",
                content: "You acknowledge and agree that Nucleon will have no obligation to provide you with any support or maintenance in connection with the Site.",
            },
            {
                heading: "2.5 Ownership",
                content: "Excluding any User Content that you may provide (defined below), you acknowledge that all the intellectual property rights, including copyrights, patents, trademarks, and trade secrets, in the Site and its content are owned by Nucleon or Nucleon’s suppliers. Neither these Terms (nor your access to the Site) transfers to you or any third party any rights, title or interest in or to such intellectual property rights, except for the limited access rights expressly set forth in Section 2.1. Nucleon and its suppliers reserve all rights not granted in these Terms. There are no implied licenses granted under these Terms.",
            },
            {
                heading: "2.6 Feedback",
                content: "If you provide Nucleon with any feedback or suggestions regarding the Site (<b>“Feedback”</b>), you hereby assign to Nucleon all rights in such Feedback and agree that Nucleon shall have the right to use and fully exploit such Feedback and related information in any manner it deems appropriate.",
            },
        ],
    },
    {
        heading: "3. User Content",
        content: [
            {
                heading: "3.1 User Content",
                content: "<b>“User Content”</b> means any and all information and content that a user submits to, or uses with, the Site (e.g., content in the user’s profile or postings). You are solely responsible for your User Content. You assume all risks associated with use of your User Content, including any reliance on its accuracy, completeness or usefulness by others, or any disclosure of your User Content that personally identifies you or any third party. You hereby represent and warrant that your User Content does not violate our Acceptable Use Policy (defined in Section 3.2). You may not represent or imply to others that your User Content is in any way provided, sponsored or endorsed by Nucleon. Since you alone are responsible for your User Content, you may expose yourself to liability if, for example, your User Content violates the Acceptable Use Policy. Nucleon is not obligated to backup any User Content, and your User Content may be deleted at any time without prior notice. You are solely responsible for creating and maintaining your own backup copies of your User Content if you desire.",
            },
            {
                heading: "3.2 Acceptable Use Policy",
                content: `The following terms constitute our <b>“Acceptable Use Policy”</b>:<br/> (a) You agree not to use the Site to collect, upload, transmit, display, or distribute any User Content (i) that violates any third-party right, including any copyright, trademark, patent, trade secret, moral right, privacy right, right of publicity, or any other intellectual property or proprietary right, (ii) that is unlawful, harassing, abusive, tortious, threatening, harmful, invasive of another’s privacy, vulgar, defamatory, false, intentionally misleading, trade libelous, pornographic, obscene, patently offensive, promotes racism, bigotry, hatred, or physical harm of any kind against any group or individual or is otherwise objectionable, (iii) that is harmful to minors in any way, or (iv) that is in violation of any law, regulation, or obligations or restrictions imposed by any third party.<br/> (b) In addition, you agree not to: (i) upload, transmit, or distribute to or through the Site any
                computer viruses, worms, or any software intended to damage or alter a computer system or data; (ii) send through
                the Site unsolicited or unauthorized advertising, promotional materials, junk mail, spam, chain letters, pyramid
                schemes, or any other form of duplicative or unsolicited messages, whether commercial or otherwise; (iii) use the
                Site to harvest, collect, gather or assemble information or data regarding other users, including e-mail addresses,
                without their consent; (iv) interfere with, disrupt, or create an undue burden on servers or networks connected to the
                Site, or violate the regulations, policies or procedures of such networks; (v) attempt to gain unauthorized access to
                the Site (or to other computer systems or networks connected to or used together with the Site), whether through
                password mining or any other means; (vi) harass or interfere with any other user’s use and enjoyment of the Site; or
                (vi) use software or automated agents or scripts to produce multiple accounts on the Site, or to generate automated
                searches, requests, or queries to (or to strip, scrape, or mine data from) the Site (provided, however, that we
                conditionally grant to the operators of public search engines revocable permission to use spiders to copy materials
                from the Site for the sole purpose of and solely to the extent necessary for creating publicly available searchable
                indices of the materials, but not caches or archives of such materials, subject to the parameters set forth in our
                robots.txt file).`,
            },
            {
                heading: "3.3 Enforcement",
                content: "We reserve the right (but have no obligation) to review, refuse and/or remove any User Content in our sole discretion, and to investigate and/or take appropriate action against you in our sole discretion if you violate the Acceptable Use Policy or any other provision of these Terms or otherwise create liability for us or any other person. Such action may include removing or modifying your User Content, terminating your Account in accordance with Section 8, and/or reporting you to law enforcement authorities.",
            },
        ],
    },
    {
        heading: "4. Indemnification",
        content: "You agree to indemnify and hold Nucleon (and its officers, employees, and agents) harmless, including costs and attorneys’ fees, from any claim or demand made by any third party due to or arising out of (a) your use of the Site, (b) your violation of these Terms, (c) your violation of applicable laws or regulations or (d) your User Content. Nucleon reserves the right, at your expense, to assume the exclusive defense and control of any matter for which you are required to indemnify us, and you agree to cooperate with our defense of these claims.You agree not to settle any matter without the prior written consent of Nucleon. Nucleon will use reasonable efforts to notify you of any such claim, action or proceeding upon becoming aware of it.",
    },
    {
        heading: "5. Third-Party Links & Ads; Other Users",
        content: [
            {
                heading: "5.1 Third-Party Links & Ads",
                content: "The Site may contain links to third-party websites and services, and/or display advertisements for third parties (collectively, <b>“Third-Party Links & Ads”</b>). Such Third-Party Links & Ads are not under the control of Nucleon, and Nucleon is not responsible for any Third-Party Links & Ads. Nucleon provides access to these Third-Party Links & Ads only as a convenience to you, and does not review, approve, monitor, endorse, warrant, or make any representations with respect to Third-Party Links & Ads. You use all Third-Party Links & Ads at your own risk and should apply a <br/> suitable level of caution and discretion in doing so.When you click on any of the Third-Party Links & Ads, the applicable third party’s terms and policies apply, including the third party’s privacy and data gathering practices. You should make whatever investigation you feel necessary or appropriate before proceeding with any transaction in connection with such Third-Party Links & Ads.",
            },
            {
                heading: "5.2 Other Users",
                content: "Each Site user is solely responsible for any and all of its own User Content. Since we do not control User Content, you acknowledge and agree that we are not responsible for any User Content, whether provided by you or by others. We make no guarantees regarding the accuracy, currency, suitability, appropriateness, or quality of any User Content. Your interactions with other Site users are solely between you and such users. You agree that Nucleon will not be responsible for any loss or damage incurred as the result of any such interactions. If there is a dispute between you and any Site user, we are under no obligation to become involved.",
            },
            {
                heading: "5.3 Release.",
                content: ` You hereby release and forever discharge Nucleon (and our officers,employees, agents, successors, and assigns) from, and hereby waive and relinquish, each and every past, present and future dispute, claim, controversy, demand, right, obligation, liability, action and cause of action of every kind and nature (including personal injuries, death, and property damage), that has arisen or arises directly or indirectly out of, or that relates directly or indirectly to, the Site (including any interactions with, or act or omission of, other Site users or any Third-Party Links & Ads). IF YOU ARE A CALIFORNIA RESIDENT, YOU HEREBY WAIVE CALIFORNIA CIVIL CODE SECTION 1542 IN CONNECTION WITH THE FOREGOING, WHICH STATES:“A GENERAL RELEASE DOES NOT EXTEND TO CLAIMS WHICH THE CREDITOR OR RELEASING PARTY DOES NOT KNOW OR SUSPECT TO EXIST IN HIS OR HER FAVOR AT THE TIME OF EXECUTING THE RELEASE, WHICH IF KNOWN BY HIM OR HER MUST HAVE MATERIALLY AFFECTED HIS OR HER SETTLEMENT WITH THE DEBTOR OR RELEASED PARTY.`
            }
        ],
    },
    {
        heading: "6. Disclaimers",
        content: "THE SITE IS PROVIDED ON AN “AS-IS” AND “AS AVAILABLE” BASIS, AND COMPANY (AND OUR SUPPLIERS) EXPRESSLY DISCLAIM ANY AND ALL WARRANTIES AND CONDITIONS OF ANY KIND, WHETHER EXPRESS, IMPLIED, OR STATUTORY, INCLUDING ALL WARRANTIES OR CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, QUIET ENJOYMENT, ACCURACY, OR NON-INFRINGEMENT. WE (AND OUR SUPPLIERS) MAKE NO WARRANTY THAT THE SITE WILL MEET YOUR REQUIREMENTS, WILL BE AVAILABLE ON AN UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE BASIS, OR WILL BE ACCURATE, RELIABLE, FREE OF VIRUSES OR OTHER HARMFUL CODE, COMPLETE, LEGAL, OR SAFE. IF APPLICABLE LAW REQUIRES ANY WARRANTIES WITH RESPECT TO THE SITE, ALL SUCH WARRANTIES ARE LIMITED IN DURATION TO NINETY (90) DAYS FROM THE DATE OF FIRST USE.<br/> SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF IMPLIED WARRANTIES, SO THE ABOVE EXCLUSION MAY NOT APPLY TO YOU. SOME JURISDICTIONS DO NOT ALLOW LIMITATIONS ON HOW LONG AN IMPLIED WARRANTY LASTS, SO THE ABOVE LIMITATION MAY NOT APPLY TO YOU",
    },
    {
        heading: "7. Limitation on Liability",
        content: "TO THE MAXIMUM EXTENT PERMITTED BY LAW, IN NO EVENT SHALL COMPANY (OR OUR SUPPLIERS) BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY LOST PROFITS, LOST DATA, COSTS OF PROCUREMENT OF SUBSTITUTE PRODUCTS, OR ANY INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL OR PUNITIVE DAMAGES ARISING FROM OR RELATING TO THESE TERMS OR YOUR USE OF, OR INABILITY TO USE, THE SITE, EVEN IF COMPANY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. ACCESS TO, AND USE OF, THE SITE IS AT YOUR OWN DISCRETION AND RISK, AND YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR DEVICE OR COMPUTER SYSTEM, OR LOSS OF DATA RESULTING THEREFROM. <br/>TO THE MAXIMUM EXTENT PERMITTED BY LAW, NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN, OUR LIABILITY TO YOU FOR ANY DAMAGES ARISING FROM OR RELATED TO THESE TERMS (FOR ANY CAUSE WHATSOEVER AND REGARDLESS OF THE FORM OF THE ACTION), WILL AT ALL TIMES BE LIMITED TO A MAXIMUM OF FIFTY US DOLLARS (U.S. $50). THE EXISTENCE OF MORE THAN ONE CLAIM WILL NOT ENLARGE THIS LIMIT. YOU AGREE THAT OUR SUPPLIERS WILL HAVE NO LIABILITY OF ANY KIND ARISING FROM OR RELATING TO THESE TERMS.<br/> SOME JURISDICTIONS DO NOT ALLOW THE LIMITATION OR EXCLUSION OF LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE ABOVE LIMITATION OR EXCLUSION MAY NOT APPLY TO YOU. ",
    },
    {
        heading: "8. Term and Termination",
        content: "Subject to this Section, these Terms will remain in full force and effect while you use the Site. We may suspend or terminate your rights to use the Site (including your Account) at any time for any reason at our sole discretion, including for any use of the Site in violation of these Terms. Upon termination of your rights under these Terms, your Account and right to access and use the Site will terminate immediately. You understand that any termination of your Account may involve deletion of your User Content associated with your Account from our live databases. Nucleon will not have any liability whatsoever to you for any termination of your rights under these Terms, including for termination of your Account or deletion of your User Content. Even after your rights under these Terms are terminated, the following provisions of these Terms will remain in effect: Sections 2.2 through 2.6, Section 3 and Sections 4 through 10.",
    },
    {
        heading: "9. General",
        content: [
            {
                heading: "9.1 Changes",
                content: "These Terms are subject to occasional revision, and if we make any substantial changes, we may notify you by sending you an e-mail to the last e-mail address you provided to us (if any), and/or by prominently posting notice of the changes on our Site. You are responsible for providing us with your most current e-mail address. In the event that the last e-mail address that you have provided us is not valid, or for any reason is not capable of delivering to you the notice described above, our dispatch of the e-mail containing such notice will nonetheless constitute effective notice of the changes described in the notice. Continued use of our Site following notice of such changes shall indicate your acknowledgement of such changes and agreement to be bound by the terms and conditions of such changes.",
            },
            {
                heading: "9.2 Dispute Resolution",
                content: "<b>Please read this Section 10.2 (sometimes referred to herein as this 'Arbitration Agreement') carefully. It is part of your contract with Company and affects your rights. It contains procedures for MANDATORY BINDING ARBITRATION AND A CLASS ACTION WAIVER.</b>",
            },
            {
                heading: "",
                content: `<b>(a) Applicability of Arbitration Agreement.</b> All claims and disputes (excluding claims for injunctive or other equitable relief as set forth below) in connection with these Terms or the use of any product or service provided by Nucleon that cannot be resolved informally or in small claims court shall be resolved by binding arbitration on an individual basis under the terms of this Arbitration Agreement. Unless otherwise agreed to, all arbitration proceedings shall be held in English. This Arbitration Agreement applies to you and Nucleon, and to any subsidiaries, affiliates, agents, employees, predecessors in interest, successors, and assigns, as well as all authorized or unauthorized users or beneficiaries of services or goods provided under these Terms. <br/>

                <b>(b) Notice Requirement and Informal Dispute Resolution.</b> Before either party may seek arbitration, the party must first send to the other party a written Notice of Dispute (<b>“Notice”</b>) describing the nature and basis of the claim or dispute, and the requested relief. A Notice to the Company should be sent to: 9450 SW Gemini Dr, PMB 89100, Beaverton, Oregon 97008. After the Notice is received, you and Nucleon may attempt to resolve the claim or dispute informally. If you and Nucleon do not resolve the claim or dispute within thirty (30) days after the Notice is received, either party may begin an arbitration proceeding. The amount of any settlement offer made by any party may not be disclosed to the arbitrator until after the arbitrator has determined the amount of the award, if any, to which either party is entitled.<br/>

                <b>(c) Arbitration Rules.</b> The Federal Arbitration Act governs the interpretation and enforcement of this Arbitration Agreement. The arbitration will be conducted by JAMS, an established alternative dispute resolution provider. Disputes involving claims, counterclaims, or request for relief under $250,000, not inclusive of attorneys’ fees and interest, shall be subject to JAMS’s most current version of the Streamlined Arbitration Rules and procedures available at http://www.jamsadr.com/rules-streamlined-arbitration/; all other disputes shall be subject to JAMS’s most current version of the Comprehensive Arbitration Rules and Procedures, available at http://www.jamsadr.com/rules-comprehensive-arbitration/. JAMS’s rules are also available at www.jamsadr.com or by calling JAMS at 800-352-5267. If JAMS is not available to arbitrate, the parties will select an alternative arbitral forum. Each party shall bear its own costs (including attorney’s fees) and disbursements arising out of the arbitration and shall pay an equal share of the fees and costs of the ADR Provider. <br/> You may choose to have the arbitration conducted by telephone, based on written submissions, or in person in the country where you live or at another mutually agreed location. Any judgment on the award rendered by the arbitrator may be entered in any court of competent jurisdiction.<br/>

                <b>(d) Authority of Arbitrator.</b> The arbitrator shall have exclusive authority to (i) determine the scope and enforceability of this Arbitration Agreement and (ii) resolve any dispute related to the interpretation, applicability, enforceability or formation of this Arbitration Agreement including, but not limited to, any assertion that all or any part of this Arbitration Agreement is void or voidable. The arbitration will decide the rights and liabilities, if any, of you and Nucleon. The arbitration proceeding will not be consolidated with any other matters or joined with any other cases or parties. The arbitrator shall have the authority to grant motions dispositive of all or part of any claim. The arbitrator shall have the authority to award monetary damages and to grant any non-monetary remedy or relief available to an individual under applicable law, the arbitral forum’s rules, and these Terms (including the Arbitration Agreement). The arbitrator shall issue a written award and statement of decision describing the essential findings and conclusions on which the award is based, including the calculation of any damages awarded. The arbitrator has the same authority to award relief on an individual basis that a judge in a court of law would have. The award of the arbitrator is final and binding upon you and us.<br/>

                <b>(e) Waiver of Jury Trial.</b>YOU AND NUCLEON HEREBY WAIVE ANY CONSTITUTIONAL AND STATUTORY RIGHTS TO SUE IN COURT AND HAVE A TRIAL IN FRONT
                    OF A JUDGE OR A JURY. You and Nucleon are instead electing that all disputes, claims, or requests for relief shall be resolved by arbitration under this Arbitration Agreement, except as specified in Section 10.2(a) (Applicability of Arbitration Agreement) above. An arbitrator can award on an individual basis the same damages and relief as a court and must follow these Terms as a court would. However, there is no judge or jury in arbitration, and court review of an arbitration award is subject to very limited review.<br/>

                <b>(f) Waiver of Class or Other Non-Individualized Relief.</b> ALL DISPUTES, CLAIMS, AND REQUESTS FOR RELIEF WITHIN THE SCOPE OF THIS ARBITRATION AGREEMENT MUST BE ARBITRATED ON AN INDIVIDUAL BASIS AND NOT ON A CLASS OR COLLECTIVE BASIS, ONLY INDIVIDUAL RELIEF IS AVAILABLE, AND CLAIMS OF MORE THAN ONE CUSTOMER OR USER CANNOT BE ARBITRATED OR CONSOLIDATED WITH THOSE OF ANY OTHER CUSTOMER OR USER. If a decision is issued stating that applicable law precludes enforcement of any of this section’s limitations as to a given dispute, claim, or request for relief, then such aspect must be severed from the arbitration and brought into the State or Federal Courts located in the State of Oregon. All other disputes, claims, or requests for relief shall be arbitrated.<br/>

                <b>(g) 30-Day Right to Opt Out.</b> You have the right to opt out of the provisions of this Arbitration Agreement by sending written notice of your decision to opt out to: info@nucleonspace.com, within thirty (30) days after first becoming subject to this Arbitration Agreement. Your notice must include your name and address, your Nucleon username (if any), the email address you used to set up your Nucleon account (if you have one), and an unequivocal statement that you want to opt out of this Arbitration Agreement. If you opt out of this Arbitration Agreement, all other parts of these Terms will continue to apply to you. Opting out of this Arbitration Agreement has no effect on any other arbitration agreements that you may currently have, or may enter in the future, with us.<br/>

                <b>(h) Severability.</b> Except as provided in Section 10.2(f) (Waiver of Class or Other Non-Individualized Relief), if any part or parts of this Arbitration Agreement are found under the law to be invalid or unenforceable, then such specific part or parts shall be of no force and effect and shall be severed and the remainder of the Arbitration Agreement shall continue in full force and effect.<br/>

                <b>(i) Survival of Agreement.</b> This Arbitration Agreement will survive the termination of your relationship with Nucleon.<br/>

                <b>(j) Modification.</b> Notwithstanding any provision in these Terms to the contrary, we agree that if Nucleon makes any future material change to this Arbitration Agreement, you may reject that change within thirty (30) days of such change becoming effective by writing Nucleon at the following address: admin@cyclotechnologies.com.<br/>`
            },
            {
                heading: "9.3 Export",
                content: "The Site may be subject to U.S. export control laws and may be subject to export or import regulations in other countries. You agree not to export, reexport, or transfer, directly or indirectly, any U.S. technical data acquired from Nucleon, or any products utilizing such data, in violation of the United States export laws or regulations.",
            },
            {
                heading: "9.4 Disclosures",
                content: "Nucleon is located at the address in Section 10.8. If you are a California resident, you may report complaints to the Complaint Assistance Unit of the Division of Consumer Product of the California Department of Consumer Affairs by contacting them in writing at 400 R Street, Sacramento, CA 95814, or by telephone at (800) 952-5210.",
            },
            {
                heading: "9.5 Electronic Communications",
                content: "The communications between you and Nucleon use electronic means, whether you use the Site or send us emails, or whether Nucleon posts notices on the Site or communicates with you via email. For contractual purposes, you (a) consent to receive communications from Nucleon in an electronic form; and (b) agree that all terms and conditions, agreements, notices, disclosures, and other communications that Nucleon provides to you electronically satisfy any legal requirement that such communications would satisfy if it were be in a hardcopy writing. The foregoing does not affect your non-waivable rights.",
            },
            {
                heading: "9.6 Entire Terms",
                content: "These Terms constitute the entire agreement between you and us regarding the use of the Site. Our failure to exercise or enforce any right or provision of these Terms shall not operate as a waiver of such right or provision. The section titles in these Terms are for convenience only and have no legal or contractual effect. The word  “including” means “including without limitation”. If any provision of these Terms is, for any reason, held to be invalid or unenforceable, the other provisions of these Terms will be unimpaired and the invalid or unenforceable provision will be deemed modified so that it is valid and enforceable to the maximum extent permitted by law.Your relationship to Nucleon is that of an independent contractor, and neither party is an agent or partner of the other. These Terms, and your rights and obligations herein, may not be assigned, subcontracted, delegated, or otherwise transferred by you without Nucleon’s prior written consent, and any attempted assignment, subcontract, delegation, or transfer in violation of the foregoing will be null and void. Nucleon may freely assign these Terms. The terms and conditions set forth in these Terms shall be binding upon assignees.",
            },
            {
                heading: "9.7 Copyright/Trademark Information",
                content: "Copyright © 2024 Nucleon. All rights reserved. All trademarks, logos and service marks (<b>“Marks”</b>) displayed on the Site are our property or the property of other third parties. You are not permitted to use these Marks without our prior written consent or the consent of such third party which may own the Marks.",
            },
            {
                heading: "9.8 Contact Information",
                content: "<br/> Address: <br/> 3220 118th Avenue SE Suite 105 Bellevue, WA 98005 <br/> Telephone: (912) 470-6152 <br/>Email: info@nucleonspace.com",
            },
        ],
    },
];

export const privacyPolicy = [
    {
        heading: "",
        content: [
            {
                heading: "",
                content: "Nucleon (Cyclo Technologies, Inc DBA Nucleon) (the “Company”) is committed to maintaining robust privacy protections for its users. Our Privacy Policy (“Privacy Policy”) is designed to help you understand how we collect, use and safeguard the information you provide to us and to assist you in making informed decisions when using our Service."
            },
            {
                heading: "",
                content: "For purposes of this Agreement, “Site” refers to the Company’s website, which can be accessed at www.nucleonspace.com."
            },
            {
                heading: "",
                content: "“Service” refers to the Company’s services accessed via the Site, in which users can register for an account in order access parts testing database and request additional consulting services."
            },
            {
                heading: "",
                content: "The terms “we,” “us,” and “our” refer to the Company."
            },
            {
                heading: "",
                content: "“You” refers to you, as a user of our Site or our Service."
            },
            {
                heading: "",
                content: "By accessing our Site or our Service, you accept our Privacy Policy and Terms of Use, and you consent to our collection, storage, use and disclosure of your Personal Information as described in this Privacy Policy."
            },
        ]
    },
    {
        heading: "I. INFORMATION WE COLLECT",
        content: [
            {
                heading: "",
                content: 'We collect “Non-Personal Information” and “Personal Information.” Non-Personal Information includes information that cannot be used to personally identify you, such as anonymous usage data, general demographic information we may collect, referring/exit pages and URLs, platform types, preferences you submit and preferences that are generated based on the data you submit and number of clicks. Personal Information includes your name and contact information, which you submit to us through the registration process at the Site.'
            },
            {
                heading: "1. Information collected via Technology",
                content: "To activate the Service you do not need to submit any Personal Information other than your name and contact information. However, in an effort to improve the quality of the Service, we track information provided to us by your browser or by our software application when you view or use the Service, such as the website you came from (known as the “referring URL”),the type of browser you use, the device from which you connected to the Service, the time and date of access, and other information that does not personally identify you. We track this information using cookies, or small text files which include an anonymous unique identifier.Cookies are sent to a user’s browser from our servers and are stored on the user’s computer hard drive. Sending a cookie to a user’s browser enables us to collect Non-Personal information about that user and keep a record of the user’s preferences when utilizing our services, both on an individual and aggregate basis. The Company may use both persistent and session cookies; persistent cookies remain on your computer after you close your session and until you delete them, while session cookies expire when you close your browser."
            },
            {
                heading: "2. Information you provide us by registering for an account",
                content: "In addition to the information provided automatically by your browser when you visit the Site, to become a subscriber to the Service you will need to create a personal profile. You can create a profile by registering with the Service and entering your email address, and creating a user name and a password. By registering, you are authorizing us to collect, store and use your email address in accordance with this Privacy Policy."
            },
            {
                heading: "3. Children’s Privacy",
                content: "The Site and the Service are not directed to anyone under the age of 13. The Site does not knowingly collect or solicit information from anyone under the age of 13, or allow anyone under the age of 13 to sign up for the Service. In the event that we learn that we have gathered personal information from anyone under the age of 13 without the consent of a parent or guardian, we will delete that information as soon as possible. If you believe we have collected such information, please contact us at info@nucleonspace.com"
            },
        ]
    },
    {
        heading: "II. HOW WE USE AND SHARE INFORMATION",
        content: [
            {
                heading: "Personal Information:",
                content: "Except as otherwise stated in this Privacy Policy, we do not sell, trade, rent or otherwise share for marketing purposes your Personal Information with third parties without your consent. We do share Personal Information with vendors who are performing services for the Company,such as the servers for our email communications who are provided access to user’s email address for purposes of sending emails from us. Those vendors use your Personal Information only at our direction and in accordance with our Privacy Policy.In general, the Personal Information you provide to us is used to help us communicate with you. For example, we use Personal Information to contact users in response to questions,solicit feedback from users, provide technical support, and inform users about promotional offers.<br/>We may share Personal Information with outside parties if we have a good-faith belief that access, use, preservation or disclosure of the information is reasonably necessary to meet any applicable legal process or enforceable governmental request; to enforce applicable Terms of Service, including investigation of potential violations; address fraud, security or technical concerns; or to protect against harm to the rights, property, or safety of our users or the public as required or permitted by law."
            },
            {
                heading: "Non-Personal Information:",
                content: "In general, we use Non-Personal Information to help us improve the Service and customize the user experience. We also aggregate Non-Personal Information in order to track trends and analyze use patterns on the Site. This Privacy Policy does not limit in any way our use or disclosure of Non-Personal Information and we reserve the right to use and disclose such Non-Personal Information to our partners, advertisers and other third parties at our discretion.In the event we undergo a business transaction such as a merger, acquisition by another company, or sale of all or a portion of our assets, your Personal Information may be among the assets transferred. You acknowledge and consent that such transfers may occur and are permitted by this Privacy Policy, and that any acquirer of our assets may continue to process your Personal Information as set forth in this Privacy Policy. If our information practices change at any time in the future, we will post the policy changes to the Site so that you may opt out of the new information practices. We suggest that you check the Site periodically if you are concerned about how your information is used"
            }
        ]
    },
    {
        heading: "III. HOW WE PROTECT INFORMATION",
        content: 'We implement security measures designed to protect your information from unauthorized access. Your account is protected by your account password and we urge you to take steps to keep your personal information safe by not disclosing your password and by logging out of your account after each use. We further protect your information from potential security breaches by implementing certain technological security measures including encryption,firewalls and secure socket layer technology. However, these measures do not guarantee that your information will not be accessed, disclosed, altered or destroyed by breach of such firewalls and secure server software. By using our Service, you acknowledge that you understand and agree to assume these risks.'
    },
    {
        heading: "IV. YOUR RIGHTS REGARDING THE USE OF YOUR PERSONAL INFORMATION",
        content: 'You have the right at any time to prevent us from contacting you for marketing purposes. When we send a promotional communication to a user, the user can opt out of further promotional communications by following the unsubscribe instructions provided in each promotional e-mail.'
    },
    {
        heading: "V. Links to Other Websites",
        content: 'As part of the Service, we may provide links to or compatibility with other websites or applications. However, we are not responsible for the privacy practices employed by those websites or the information or content they contain. This Privacy Policy applies solely to information collected by us through the Site and the Service. Therefore, this Privacy Policy does not apply to your use of a third party website accessed by selecting a link on our Site or via our Service. To the extent that you access or use the Service through or on another website or application, then the privacy policy of that other website or application will apply to your access or use of that site or application. We encourage our users to read the privacy statements of other websites before proceeding to use them.'
    },
    {
        heading: "VI. Changes to Our Privacy Policy",
        content: 'The Company reserves the right to change this policy and our Terms of Service at any time. We will notify you of significant changes to our Privacy Policy by sending a notice to the primary email address specified in your account or by placing a prominent notice on our site. Significant changes will go into effect 30 days following such notification. Non-material changes or clarifications will take effect immediately. You should periodically check the Site and this privacy page for updates.',
    },
    {
        heading: "VII. Contact Us",
        content: 'If you have any questions regarding this Privacy Policy or the practices of this Site, please contact us by sending an email to info@nucleonspace.com'
    },
    {
        heading: "",
        content: "Last Updated: This Privacy Policy was last updated on 10/15/2024."
    }
];