import React, { useState } from 'react';
import { Checkbox, FormControlLabel, TextField, Button, Box, Autocomplete, styled } from '@mui/material';
import { sanitizeInput } from '../../helper/helper';

const AddDataSourceForm = () => {
    const [formData, setFormData] = useState({
        datasources_title: '',
        datasources_weblink: '',
        datasources_cachelink: '',
        datasources_test_type: '',
        datasources_doi: '',
        datasources_year: '',
        hidden: false
    });
    const [statusMessage, setStatusMessage] = useState(null);

    const [selectedTestType, setSelectedTestType] = useState(null);

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: type === 'checkbox' ? sanitizeInput(checked) : sanitizeInput(value)
        }));
    };

    const CustomTextField = styled(TextField)(() => ({
        '& .MuiOutlinedInput-root': {
            borderRadius: 8,
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: 'black',
            },
        },
        '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: 'rgba(145, 158, 171, 0.8)',
        },
        '& .MuiOutlinedInput-notchedOutline': {
            borderRadius: 8,
            borderColor: 'rgba(145, 158, 171, 0.2)',
        },
        '& .MuiInputLabel-root': {
            color: 'rgba(145, 158, 171)',
            '&.Mui-focused': {
                color: 'black',
            },
        },
        '& .MuiInputBase-input::placeholder': {
            color: 'rgba(145, 158, 171, 0.8)',
            opacity: 1,
        },
        '& .MuiInputBase-input:focus::placeholder': {
            color: 'rgba(145, 158, 171, 0.8)',
        },
    }));

    const handleSubmit = async (event) => {
        event.preventDefault();
        setStatusMessage(null);

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/operations/add-datasource`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                credentials: 'include',
                body: JSON.stringify({
                    formData: {
                        ...formData,
                        hidden: formData.hidden ? 1 : 0,
                        datasources_test_type: selectedTestType.value,
                    }
                }),
            });

            const data = await response.json();
            if (response.ok) {
                setStatusMessage({ type: 'success', text: 'Data Source added successfully!' });
            } else {
                throw new Error(data.message || 'Failed to add part');
            }
        } catch (error) {
            setStatusMessage({ type: 'error', text: error.message });
        }
    };

    const testTypeOptions = [
        { value: 'Heavy-Ion', label: 'Heavy-Ion' },
        { value: 'Neutron', label: 'Neutron' },
        { value: 'Proton', label: 'Proton' },
        { value: 'Laser', label: 'Laser' },
        { value: 'Total-Ionizing Dose', label: 'Total-Ionizing Dose' },
        { value: 'Electron', label: 'Electron' },
        { value: 'Alpha Foil', label: 'Alpha Foil' },
        { value: 'Orbit', label: 'Orbit' },
        { value: 'Displacement Damage', label: 'Displacement Damage' },
        { value: 'Other', label: 'Other' },
    ];


    const inputSx = {
        marginBottom: '1px',
        "& .MuiOutlinedInput-root": {
            borderRadius: 2,
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: "black",
            },
        },
        "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "rgba(145, 158, 171, 0.8)",
        },
        "& .MuiOutlinedInput-notchedOutline": {
            borderRadius: 2,
            borderColor: "rgba(145, 158, 171, 0.2)",
        },
        "& .MuiInputLabel-root": {
            color: "rgba(145 158 171)",
            "&.Mui-focused": {
                color: "black",
            },
        },
        "& .MuiInputBase-input::placeholder": {
            color: "rgba(145, 158, 171, 0.8)",
            opacity: 1,
        },
        "& .MuiInputBase-input:focus::placeholder": {
            color: "rgba(145, 158, 171, 0.8)",
        },
    };


    return (
        <form id='add-data-source-form' onSubmit={handleSubmit} >
            <TextField
                style={{
                    width: "100%",
                    marginRight: "0",
                    marginBottom: "20px",
                }}
                label="Data Source Title"
                type="text"
                name="datasources_title"
                value={formData.datasources_title}
                onChange={handleChange}
                sx={{
                    "& .MuiOutlinedInput-root": {
                        borderRadius: 2,
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            borderColor: "black",
                        },
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: "rgba(145, 158, 171, 0.8)",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                        borderRadius: 2,
                        borderColor: "rgba(145, 158, 171, 0.2)",
                    },
                    "& .MuiInputLabel-root": {
                        color: "rgba(145 158 171)",
                        "&.Mui-focused": {
                            color: "black",
                        },
                    },
                    "& .MuiInputBase-input::placeholder": {
                        color: "rgba(145, 158, 171, 0.8)",
                        opacity: 1,
                    },
                    "& .MuiInputBase-input:focus::placeholder": {
                        color: "rgba(145, 158, 171, 0.8)",
                    },
                }}
            />
            <TextField
                style={{
                    width: "100%",
                    marginRight: "0",
                    marginBottom: "20px",
                }}
                label="Weblink"
                type="text"
                name="datasources_weblink"
                value={formData.datasources_weblink}
                onChange={handleChange}
                sx={{
                    "& .MuiOutlinedInput-root": {
                        borderRadius: 2,
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            borderColor: "black",
                        },
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: "rgba(145, 158, 171, 0.8)",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                        borderRadius: 2,
                        borderColor: "rgba(145, 158, 171, 0.2)",
                    },
                    "& .MuiInputLabel-root": {
                        color: "rgba(145 158 171)",
                        "&.Mui-focused": {
                            color: "black",
                        },
                    },
                    "& .MuiInputBase-input::placeholder": {
                        color: "rgba(145, 158, 171, 0.8)",
                        opacity: 1,
                    },
                    "& .MuiInputBase-input:focus::placeholder": {
                        color: "rgba(145, 158, 171, 0.8)",
                    },
                }}
            />
            <TextField
                style={{
                    width: "100%",
                    marginRight: "0",
                    marginBottom: "20px",
                }}
                label="Cache Link"
                type="text"
                name="datasources_cachelink"
                value={formData.datasources_cachelink}
                onChange={handleChange}
                sx={{
                    "& .MuiOutlinedInput-root": {
                        borderRadius: 2,
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            borderColor: "black",
                        },
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: "rgba(145, 158, 171, 0.8)",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                        borderRadius: 2,
                        borderColor: "rgba(145, 158, 171, 0.2)",
                    },
                    "& .MuiInputLabel-root": {
                        color: "rgba(145 158 171)",
                        "&.Mui-focused": {
                            color: "black",
                        },
                    },
                    "& .MuiInputBase-input::placeholder": {
                        color: "rgba(145, 158, 171, 0.8)",
                        opacity: 1,
                    },
                    "& .MuiInputBase-input:focus::placeholder": {
                        color: "rgba(145, 158, 171, 0.8)",
                    },
                }}
            />
            {/* <label style={{ display: 'block', marginBottom: '10px' }}>
                Test Type:
                <select
                    id="datasources-test-type-dropdown"
                    name="datasources_test_type"
                    value={formData.datasources_test_type}
                    onChange={handleChange}
                    style={{ display: 'block', margin: '5px 0', padding: '8px', width: '100%', backgroundColor: 'white', color: 'black' }}
                >
                    {testTypeOptions.map((option) => (
                        <option key={option.value} value={option.value} style={{ color: 'black' }}>
                            {option.label}
                        </option>
                    ))}
                </select>
            </label> */}

            <Autocomplete
                id="datasources-test-type-autocomplete"
                options={testTypeOptions}
                getOptionLabel={(option) => option.label}
                renderInput={(params) => (
                    <CustomTextField {...params} label="datasources_test_type" sx={inputSx} />
                )}
                value={selectedTestType}
                onChange={(event, newValue) => setSelectedTestType(newValue)}
                fullWidth
                sx={{ mb: 3 }}
            />



            <TextField
                style={{
                    width: "100%",
                    marginRight: "0",
                    marginBottom: "20px",
                }}
                label="DOI"
                type="text"
                name="datasources_doi"
                value={formData.datasources_doi}
                onChange={handleChange}
                sx={{
                    "& .MuiOutlinedInput-root": {
                        borderRadius: 2,
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            borderColor: "black",
                        },
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: "rgba(145, 158, 171, 0.8)",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                        borderRadius: 2,
                        borderColor: "rgba(145, 158, 171, 0.2)",
                    },
                    "& .MuiInputLabel-root": {
                        color: "rgba(145 158 171)",
                        "&.Mui-focused": {
                            color: "black",
                        },
                    },
                    "& .MuiInputBase-input::placeholder": {
                        color: "rgba(145, 158, 171, 0.8)",
                        opacity: 1,
                    },
                    "& .MuiInputBase-input:focus::placeholder": {
                        color: "rgba(145, 158, 171, 0.8)",
                    },
                }}
            />
            <TextField
                style={{
                    width: "100%",
                    marginRight: "0",
                    marginBottom: "20px",
                }}
                label="Year"
                type="text"
                name="datasources_year"
                value={formData.datasources_year}
                onChange={handleChange}
                sx={{
                    "& .MuiOutlinedInput-root": {
                        borderRadius: 2,
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            borderColor: "black",
                        },
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: "rgba(145, 158, 171, 0.8)",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                        borderRadius: 2,
                        borderColor: "rgba(145, 158, 171, 0.2)",
                    },
                    "& .MuiInputLabel-root": {
                        color: "rgba(145 158 171)",
                        "&.Mui-focused": {
                            color: "black",
                        },
                    },
                    "& .MuiInputBase-input::placeholder": {
                        color: "rgba(145, 158, 171, 0.8)",
                        opacity: 1,
                    },
                    "& .MuiInputBase-input:focus::placeholder": {
                        color: "rgba(145, 158, 171, 0.8)",
                    },
                }}
            />
            <FormControlLabel
                control={
                    <Checkbox
                        checked={formData.hidden}
                        onChange={handleChange}
                        name="hidden"
                        sx={{
                            color: 'white',
                            '&.Mui-checked': {
                                color: 'white'
                            }
                        }}
                    />
                }
                label="Hidden"
                sx={{ color: 'white' }}
            />
            <Box sx={{ display: "flex", justifyContent: "end", alignItems: "end" }}>
                <Button
                    type="submit"
                    sx={{
                        mt: 3,
                        mb: 1,
                        borderRadius: "12px",
                        backgroundColor: "#F26E21",
                        color: "#fff",
                        textAlign: "center",
                        fontSize: "14px",
                        px: 2,
                        py: 2,
                        minWidth: 200,
                        height: "48px",
                        cursor: "pointer",
                        fontFamily: "'Poppins', sans-serif",
                        textTransform: "capitalize",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        "&:hover": {
                            backgroundColor: "#F26E21",
                            boxShadow: "none",
                        },
                        "&:active": {
                            backgroundColor: "#F26E21",
                            boxShadow: "none",
                        },
                        "&.Mui-focusVisible": {
                            backgroundColor: "#F26E21",
                            boxShadow: "none",
                        },
                    }}
                >
                    Submit
                </Button>
            </Box>
            {statusMessage && (
                <div style={{ color: statusMessage.type === 'success' ? 'green' : 'red', marginTop: '10px' }}>
                    {statusMessage.text}
                </div>
            )}
        </form>
    );
};

export default AddDataSourceForm;