import React from 'react';
import {
  Card,
  CardContent,
  Grid,
  Typography,
  Box,
  // Button,
} from '@mui/material';
import AnimateButton from '../Button/AnimateButton';
import ButtonGroup from '@mui/material/ButtonGroup';
import { FaCircle } from 'react-icons/fa';
import SectionHeader from '../SectionHeader/section-header';

const Product = () => {
  return (
    <Box id='homepage-product-section'>
      <SectionHeader title="Products" subtitle="Explore the Nucleon Database" />
      <Grid sx={{ my: 5 }} container spacing={4}>
        <Grid item xs={12} md={5}>
          <iframe
            id='products-video-iframe'
            width="100%"
            height="350"
            src="https://www.youtube.com/embed/E3foZXAV3E0"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Web Development 101 Video"
            style={{
              borderRadius: '20px',
              boxShadow: ' none',
            }}
          ></iframe>
        </Grid>

        <Grid id='products-radiation-test-section-description' item xs={12} md={7}>
          <Card
            sx={{
              backgroundColor: '#fff',
              color: '#000',
              borderRadius: '20px',
              minHeight: '350px',
              boxShadow: 'none',
              position: 'relative',
            }}
          >
            <CardContent>
              <Typography
                variant="h5"
                sx={{
                  pl: 2,
                  pt: 1,
                  mb: 0,
                  fontFamily: "'Poppins', sans-serif",
                }}
                gutterBottom
              >
                Expert-Curated Radiation Test Data Database
              </Typography>
              <Box
                component="ul"
                sx={{
                  color: 'rgb(85, 95, 109)',
                  fontSize: '12px',
                  fontFamily: "'Poppins', sans-serif",
                  pl: 2,
                  pt: 1,
                  listStyle: 'none',
                }}
              >
                <Box
                  component="li"
                  sx={{
                    py: 0,
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    gap: 1.5,
                    fontSize: { xs: '14px', sm: '14px', lg: '15px' },
                    mb: '8px',
                    fontFamily: "'Poppins', sans-serif",
                  }}
                >
                  <FaCircle
                    color="#F26E21"
                    style={{ minWidth: 10 }}
                    size={10}
                  />
                  Curated compilation of test results from public and private
                  sources
                </Box>
                <Box
                  component="li"
                  sx={{
                    py: 0,
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    gap: 1.5,
                    fontSize: { xs: '14px', sm: '14px', lg: '15px' },
                    mb: '8px',
                    fontFamily: "'Poppins', sans-serif",
                  }}
                >
                  <FaCircle
                    color="#F26E21"
                    style={{ minWidth: 10 }}
                    size={10}
                  />
                  Categorized data with key results extraction
                </Box>

                <Box
                  component="li"
                  sx={{
                    py: 0,
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    gap: 1.5,
                    fontSize: { xs: '14px', sm: '14px', lg: '15px' },
                    mb: '8px',
                    fontFamily: "'Poppins', sans-serif",
                  }}
                >
                  <FaCircle
                    color="#F26E21"
                    style={{ minWidth: 10 }}
                    size={10}
                  />
                  Continuously refreshed, with provision for user-requested
                  updates
                </Box>
              </Box>

              <Box
                component="img"
                src="./Database_Demo_Image.jpg"
                alt="Database Demo"
                sx={{
                  width: { xs: '100%', sm: '500px' },
                  height: { xs: 'auto', sm: '350px' },
                  borderRadius: '20px',
                  border: '1px solid rgba(0, 0, 0, 0.12)',
                  padding: '8px',
                  boxSizing: 'border-box',
                  position: { xs: 'relative', sm: 'absolute' },
                  right: { xs: '0px', sm: '-40px' },
                  top: { xs: '0px', sm: '220px', lg: '200px' },
                  bottom: { xs: '0px', sm: '0' },
                  boxShadow: 'none',
                }}
              />
              <Box
                sx={{
                  position: 'absolute',
                  bottom: 0,
                  left: 0,
                  right: 0,
                  height: '35%',
                  background:
                    'linear-gradient(to top, rgba(255,255,255,1), rgba(255,255,255,0))',
                  borderRadius: '20px',
                }}
              />
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Card
            sx={{
              backgroundColor: '#FFF',
              color: '#000',
              borderRadius: '20px',
              boxShadow: 'none',
              minHeight: '350px',
            }}
          >
            <Box>
              <Grid
                container
                spacing={2}
                // alignItems="center"
                style={{ minHeight: 50 }}
              >
                <Grid item xs={12} md={7}>
                  <Box sx={{ position: 'relative' }}>
                    <Box
                      id='products-searchable-database-section-image'
                      component="img"
                      src="./Marketplace_Image.jpg"
                      alt="Radiation Test Data"
                      sx={{
                        // backgroundImage:`url("./Marketplace_Image.jpg")`
                        width: { xs: '100%', sm: '100%' },
                        height: { xs: 'auto', md: '320px', lg: '100%' },
                        display: { xs: 'none', sm: 'block' },
                        borderRadius: '20px',
                        padding: '6px',
                      }}
                    />
                    <Box
                      sx={{
                        position: 'absolute',
                        bottom: 0,
                        left: 0,
                        right: 0,
                        height: '35%',
                        background:
                          'linear-gradient(to top, rgba(255,255,255,1), rgba(255,255,255,0))',
                        borderRadius: '20px',
                      }}
                    />
                  </Box>
                </Grid>

                <Grid id='products-searchable-database-section-description' item xs={12} sx={{ mt: 1 }} md={5}>
                  <Typography
                    variant="h5"
                    sx={{
                      pl: 2,
                      pt: 1,
                      mb: 0,
                      fontFamily: "'Poppins', sans-serif",
                    }}
                    gutterBottom
                  >
                    Buy and Sell radiation test data in a searchable database
                  </Typography>
                  <Box
                    component="ul"
                    sx={{
                      color: 'rgb(85, 95, 109)',
                      fontSize: '12px',
                      fontFamily: "'Poppins', sans-serif",
                      pl: 2,
                      pt: 2,
                      pb: 1,
                      listStyle: 'none',
                    }}
                  >
                    <Box
                      component="li"
                      sx={{
                        py: 0,
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                        gap: 1.5,
                        fontSize: { xs: '14px', sm: '14px', lg: '15px' },
                        mb: '8px',
                        fontFamily: "'Poppins', sans-serif",
                      }}
                    >
                      <FaCircle
                        color="#F26E21"
                        style={{ minWidth: 10, marginTop: '5px' }}
                        size={10}
                      />
                      Curated compilation of test results from public and
                      private sources
                    </Box>
                    <Box
                      component="li"
                      sx={{
                        py: 0,
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                        gap: 1.5,
                        fontSize: { xs: '14px', sm: '14px', lg: '15px' },
                        mb: '8px',
                        fontFamily: "'Poppins', sans-serif",
                      }}
                    >
                      <FaCircle
                        color="#F26E21"
                        style={{ minWidth: 10, marginTop: '5px' }}
                        size={10}
                      />
                      Categorized data with key results extraction
                    </Box>

                    <Box
                      component="li"
                      sx={{
                        py: 0,
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                        gap: 1.5,
                        fontSize: { xs: '14px', sm: '14px', lg: '15px' },
                        mb: '8px',
                        fontFamily: "'Poppins', sans-serif",
                      }}
                    >
                      <FaCircle
                        color="#F26E21"
                        style={{ minWidth: 10, marginTop: '5px' }}
                        size={10}
                      />
                      Continuously refreshed, with provision for user-requested
                      updates
                    </Box>
                  </Box>

                  <ButtonGroup
                    id='products-searchable-database-section-button'
                    sx={{
                      display: 'flex',
                      justifyContent: 'star',
                      alignItems: 'start',
                      my: 2,
                      ml: 2,
                    }}
                  >
                    <AnimateButton href="/#pricing">
                      Subscribe Today
                    </AnimateButton>
                  </ButtonGroup>
                </Grid>
              </Grid>
            </Box>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Product;