import React from 'react';
import { Box } from '@mui/material';
import selectImage from '../../assets/select_image.png';

const FileUpload = ({ name, accept, onChange, setIsImageUploading }) => {
    const handleFileInputChange = async (e) => {
        setIsImageUploading(true);
        const file = e.target.files[0];

        const formData = new FormData();
        formData.append('file', file);

        try {
            const response = await fetch(
                `${process.env.REACT_APP_API_URL}/operations/upload_xray_image`, {
                method: 'POST',
                body: formData,
            });

            if (response.ok) {
                const data = await response.json();
                onChange(name, data.imageUrl);
                setIsImageUploading(false);
            } else {
                console.error('Error uploading the image:', response.statusText);
                setIsImageUploading(false);
            }
        } catch (error) {
            console.error('Error uploading the image:', error);
            setIsImageUploading(false);
        }
    };

    return (
        <Box>
            <input
                type="file"
                name={name}
                accept={accept}
                onChange={handleFileInputChange}
                style={{ display: 'none' }}
                id={`file-upload-${name}`}
            />
            <label htmlFor={`file-upload-${name}`}>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '200px',
                        height: '200px',
                        border: '1px solid #ccc',
                        borderRadius: '6px',
                        cursor: 'pointer'
                    }}
                >
                    <img style={{ width: '70px', height: '70px' }} src={selectImage} alt="" />
                    <p style={{ color: '#ccc', fontSize: '12px' }}>Select Image</p>
                </Box>
            </label>
        </Box>
    );
};

export default FileUpload;