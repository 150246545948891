import React, { useState } from 'react';
import { Button, TextField, Box, Typography } from '@mui/material';
import './BillOfMaterials.css';

const AddBOMForm = ({ onAddBOM, handleClose }) => {
    const [bomName, setBomName] = useState('');

    const handleSubmit = async (event) => {
        event.preventDefault();
        const bomData = {
            bom_name: bomName,
        };

        const response = await fetch(`${process.env.REACT_APP_API_URL}/dashboard/add_bom`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(bomData),
            credentials: 'include'
        });
        const newBOM = await response.json();
        onAddBOM(newBOM);
    };

    return (
        <form onSubmit={handleSubmit} style={{ height: '100%' }}>
            <TextField
                label="BOM Name"
                id='bom-name'
                // variant="standard"
                fullWidth
                required
                value={bomName}
                onChange={(e) => setBomName(e.target.value)}
                sx={{
                    mt: "15px",
                    "& .MuiOutlinedInput-root": {
                        borderRadius: 2,
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            borderColor: "black",
                        },
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: "rgba(145, 158, 171, 0.8)",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                        borderRadius: 2,
                        borderColor: "rgba(145, 158, 171, 0.2)",
                    },
                    "& .MuiInputLabel-root": {
                        color: "rgba(145 158 171)",
                        "&.Mui-focused": {
                            color: "black",
                        },
                    },
                    "& .MuiInputBase-input::placeholder": {
                        color: "rgba(145, 158, 171, 0.8)",
                        opacity: 1,
                    },
                    "& .MuiInputBase-input:focus::placeholder": {
                        color: "rgba(145, 158, 171, 0.8)",
                    },
                }}
            />
            <Box sx={{ display: "flex", justifyContent: "flex-end", alignItems: "start", gap: 3, mt: '25px' }} >
                <Typography
                    onClick={handleClose}
                    type="submit"
                    color="primary"
                    // variant="contained"
                    sx={{

                        color: "#f26e21",
                        textAlign: "center",
                        fontSize: "18px",
                        px: 3,
                        py: 1,
                        cursor: "pointer",
                        fontWeight: 500,
                        fontFamily: "'Poppins', sans-serif",

                    }}
                    style={{ marginTop: 20 }}
                >
                    Cancel
                </Typography>
                <Button
                    onClick={handleClose}
                    type="submit"
                    id='add-bom-button'
                    color="primary"
                    variant="contained"
                    sx={{
                        mt: 2,
                        mb: 3,
                        borderRadius: "12px",
                        backgroundColor: "#F26E21",
                        color: "#fff",
                        textAlign: "center",
                        fontSize: "14px",
                        px: 3,
                        py: 1,
                        cursor: "pointer",
                        fontFamily: "'Poppins', sans-serif",
                        textTransform: "capitalize",
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "flex-end",
                        "&:hover": {
                            backgroundColor: "#F26E21",
                            boxShadow: "none",
                        },
                        "&:active": {
                            backgroundColor: "#F26E21",
                            boxShadow: "none",
                        },
                        "&.Mui-focusVisible": {
                            backgroundColor: "#F26E21",
                            boxShadow: "none",
                        },
                    }}
                    style={{ marginTop: 20 }}
                >
                    Add BOM
                </Button>
            </Box>
        </form>
    );
};

export default AddBOMForm;