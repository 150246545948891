import React from 'react';
import { Box, Typography, Grid, Container, Avatar } from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import logo from '../../assets/logo.png';
import { NAVBAR_LINKS } from '../../constants/navbar';
import { Link } from 'react-router-dom';
import { LinkedIn } from '@mui/icons-material';
import PrivacyPolicyModal from "../Modals/PrivacyPolicyModal";
import TermsAndConditionsModal from "../Modals/TermsAndConditionsModal";

const servicesItems = [
  { text: "Database", href: "#" },
  { text: "Marketplace", href: "#" },
  { text: "Radiation Testing", href: "#" },
  { text: "Mission Environment Solutions", href: "#" },
];

const contactInfo = [
  {
    icon: <LocationOnIcon sx={{ color: "#F26E21" }} />,
    content: "3220 118th Ave SE STE 105, Bellevue, WA 98005",
  },
  {
    icon: <PhoneIcon sx={{ color: "#F26E21" }} />,
    content: "(912) 470-6152",
  },
  {
    icon: <EmailIcon sx={{ color: "#F26E21" }} />,
    content: "info@nucleonspace.com",
  },
];

const CustomLink = ({ href, children }) => (
  <Link
    to={href}
    color="textSecondary"
    style={{
      textDecoration: 'none',
      color: '#424040',
      fontFamily: "'Poppins', sans-serif",
    }}
  >
    {children}
  </Link>
);

const Footer = () => {
  const [openPrivacyPolicy, setOpenPrivacyPolicy] = React.useState(false);
  const [openTermsAndConditions, setOpenTermsAndConditions] = React.useState(false);
  return (
      <Box sx={{ backgroundColor: "#FFFFFF", py: 5 }}>
          <Container
              sx={{
                  px: { xs: 3, sm: 4 },
                  maxWidth: { xs: "100%", sm: "100%", md: "90%", lg: "1536px" },
              }}
          >
              <Grid container spacing={4} justifyContent="space-between">
                  <Grid item xs={12} md={3}>
                      <Box sx={{ textAlign: { xs: "center", md: "left" } }}>
                          <Typography
                              variant="h6"
                              noWrap
                              href="/"
                              sx={{
                                  mb: 2,
                                  display: "flex",
                                  fontFamily: "'Poppins', sans-serif",
                                  fontWeight: 400,
                                  letterSpacing: ".1rem",
                                  color: "inherit",
                                  textDecoration: "none",
                                  alignItems: "center",
                              }}
                          >
                              <Avatar sx={{ mr: 1, background: "transparent" }}>
                                  <img
                                      src={logo}
                                      style={{ width: "100%", height: "auto" }}
                                      alt="logo"
                                      width={100}
                                      height={100}
                                      loading="lazy"
                                  />
                              </Avatar>
                              <CustomLink href="/">NUCLEON</CustomLink>
                          </Typography>
                          <Typography
                              sx={{
                                  fontFamily: "'Poppins', sans-serif",
                                  display: "flex",
                                  justifyContent: "left",
                                  textAlign: "left",
                              }}
                              variant="body1"
                              color="textSecondary"
                              paragraph
                          >
                              A comprehensive resource for electronic component radiation
                              tolerance data.
                          </Typography>
                          <Box sx={{ display: "flex", justifyContent: "left" }}>
                              <Link
                                  to='https://www.linkedin.com/company/nucleonspace/'
                                  color="inherit"
                                  sx={{ marginRight: "10px", fontFamily: "'Poppins', sans-serif" }}
                              >
                                  <LinkedIn sx={{ color: "#F26E21" }} />
                              </Link>
                          </Box>
                      </Box>
                  </Grid>

                  <Grid item xs={12} md={2}>
                      <Typography
                          variant="h5"
                          sx={{
                              fontFamily: "'Poppins', sans-serif",
                              fontWeight: 500,
                              marginBottom: "25px",
                          }}
                          color="textPrimary"
                          gutterBottom
                      >
                          Menu
                      </Typography>
                      <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
                          {NAVBAR_LINKS.map(({ label, href }, index) => (
                              <CustomLink
                                  sx={{ fontFamily: "'Poppins', sans-serif" }}
                                  key={index}
                                  href={
                                      href === "test-solutions"
                                          ? `/${href}`
                                          : `/#${href
                                              .replace(/\s+/g, "")
                                              .toLowerCase()}`
                                  }
                              >
                                  {label}
                              </CustomLink>
                          ))}
                      </Box>
                  </Grid>

                  <Grid item xs={12} md={3}>
                      <Typography
                          variant="h5"
                          sx={{
                              fontFamily: "'Poppins', sans-serif",
                              fontWeight: 500,
                              marginBottom: "25px",
                          }}
                          color="textPrimary"
                          gutterBottom
                      >
                          Popular Services
                      </Typography>
                      <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
                          {servicesItems.map(({ text, href }, index) => (
                              <CustomLink
                                  sx={{ fontFamily: "'Poppins', sans-serif" }}
                                  key={index}
                                  href={href}
                              >
                                  {text}
                              </CustomLink>
                          ))}
                      </Box>
                  </Grid>

                  <Grid item xs={12} md={4}>
                      <Typography
                          variant="h5"
                          sx={{
                              fontFamily: "'Poppins', sans-serif",
                              fontWeight: 500,
                              marginBottom: "25px",
                          }}
                          color="textPrimary"
                          gutterBottom
                      >
                          Contact Us
                      </Typography>
                      <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
                          {contactInfo.map(({ icon, content }, index) => (
                              <Typography
                                  key={index}
                                  sx={{
                                      display: "flex",
                                      alignItems: "start",
                                      gap: 2,
                                      fontFamily: "'Poppins', sans-serif",
                                  }}
                                  variant="body2"
                                  color="textSecondary"
                              >
                                  {icon} {content}
                              </Typography>
                          ))}
                      </Box>
                  </Grid>
              </Grid>

              <Typography
                  variant="body2"
                  color="textSecondary"
                  sx={{
                      marginTop: "24px",
                      fontFamily: "'Poppins', sans-serif",
                      mt: 10,
                      mb: 2,
                      textAlign: "center",
                  }}
              >
                  Copyright © 2024 <CustomLink href="#">Nucleon</CustomLink>. All Rights
                  Reserved.
              </Typography>
              <Grid sx={{ display: "flex", justifyContent: "center" }}>
                  <Typography
                      variant="body2"
                      onClick={() => setOpenTermsAndConditions(true)}
                      color="textSecondary"
                      sx={{
                          textAlign: "center",
                          fontFamily: "'Poppins', sans-serif",
                          cursor: "pointer",
                          ":hover": {
                              color: "#F26E21",
                          }
                      }}
                  >
                      Terms of Service
                  </Typography>
                  <Typography
                      variant="body2"
                      color="textSecondary"
                      sx={{
                          textAlign: "center",
                          fontFamily: "'Poppins', sans-serif",
                          paddingInline: "10px",
                      }}
                  >
                      |
                  </Typography>
                  <Typography
                      variant="body2"
                      onClick={() => setOpenPrivacyPolicy(true)}
                      color="textSecondary"
                      sx={{
                          textAlign: "center",
                          fontFamily: "'Poppins', sans-serif",
                          cursor: "pointer",
                          ":hover": {
                              color: "#F26E21",
                          }
                      }}
                  >
                      Privacy Policy
                  </Typography>
              </Grid>
          </Container>
          <PrivacyPolicyModal handleClose={() => setOpenPrivacyPolicy(false)} open={openPrivacyPolicy} />
          <TermsAndConditionsModal handleClose={() => setOpenTermsAndConditions(false)} open={openTermsAndConditions} type={"footer"} />
      </Box>
  );
};

export default Footer;