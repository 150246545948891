import React from 'react';
import { Grid, Typography, Card, CardContent } from '@mui/material';
import { Link } from 'react-router-dom';

const SimilarParts = ({ similarParts }) => {
    return (
        <div>
            <Typography variant="h5" gutterBottom>Similar Parts in Database</Typography>

            <Grid container spacing={2}>
                {similarParts.map((part, index) => (
                    <Grid item xs={12} sm={6} md={4} key={index}>
                        <Card>
                            <CardContent>
                                <Typography variant="h6">
                                    <Link to={`/dashboard/parts/${part.manufacturer}/${part.partNumber}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                                        {part.manufacturer} {part.partNumber}
                                    </Link>
                                </Typography>
                                {/* <Typography variant="body2">Description: {part.description}</Typography> */}
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </div>
    );
};

export default SimilarParts;
