import AWS from 'aws-sdk';

AWS.config.update({
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
    region: process.env.REACT_APP_AWS_DEFAULT_REGION
});

const s3 = new AWS.S3();

export const generatePresignedUrl = (objectKey) => {
    const newObjectKey = objectKey.split('/').pop();
    const bucketName = process.env.REACT_APP_AWS_BUCKET_NAME;
    if (!bucketName) {
        console.error('Bucket name is not defined');
        return;
    }
    const params = { Bucket: bucketName, Key: newObjectKey, Expires: 900 };

    return s3.getSignedUrl('getObject', params);
}