import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { TextField, Button, Box, Autocomplete } from '@mui/material';
import useFetchPartInformation from '../../hooks/useFetchPartInformation';
import useFetchCategories from '../../hooks/useFetchCategories';
import useFetchManufacturers from '../../hooks/useFetchManufacturers';
import useEditPartInformation from '../../hooks/useEditPartInformation';
import { sanitizeInput } from '../../helper/helper';

const EditPartInformation = ({ partNumber, manufacturer, onClose }) => {
    const navigate = useNavigate(); // Initialize the navigate function
    const [formData, setFormData] = useState({
        partNumber: '',
        description: '',
        notes: '',
    });

    const { partInformation: fetchedPart } = useFetchPartInformation(partNumber, manufacturer);
    const categories = useFetchCategories();
    const [selectedCategory, setSelectedCategory] = useState(null);
    const manufacturers = useFetchManufacturers();
    const [selectedManufacturer, setSelectedManufacturer] = useState(null);
    const { editPartInformation, statusMessage } = useEditPartInformation();

    useEffect(() => {
        if (fetchedPart) {
            const newFormData = {
                partNumber: fetchedPart.partNumber || '',
                description: fetchedPart.description || '',
                notes: fetchedPart.notes || ''
            };

            if (JSON.stringify(formData) !== JSON.stringify(newFormData)) {
                setFormData(newFormData);
            }

            const newSelectedManufacturer = fetchedPart.manufacturer ? { name: fetchedPart.manufacturer } : null;
            if (JSON.stringify(selectedManufacturer) !== JSON.stringify(newSelectedManufacturer)) {
                setSelectedManufacturer(newSelectedManufacturer);
            }

            const newSelectedCategory = fetchedPart.category ? { name: fetchedPart.category } : null;
            if (JSON.stringify(selectedCategory) !== JSON.stringify(newSelectedCategory)) {
                setSelectedCategory(newSelectedCategory);
            }
        }
    }, [fetchedPart]);

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: type === 'checkbox' ? sanitizeInput(checked) : sanitizeInput(value)
        }));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        const payload = {
            partNumber: formData.partNumber,
            originalPartNumber: fetchedPart?.partNumber, // Ensure fetchedPart is not null
            description: formData.description,
            newManufacturer: selectedManufacturer,
            originalManufacturer: fetchedPart?.manufacturer, // Ensure fetchedPart is not null
            category: selectedCategory,
            notes: formData.notes
        };

        const success = await editPartInformation(payload, onClose);

        // If the part number has changed and the update was successful, navigate to the new URL
        if (success) {
            navigate(`/dashboard/parts/${encodeURIComponent(selectedManufacturer.name)}/${encodeURIComponent(formData.partNumber)}`);
            window.location.reload();
        }
    };

    return (
        <Box
            component="form"
            onSubmit={handleSubmit}
            sx={{
                backgroundColor: "white",
                boxShadow:
                  "rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px",
                alignItems: "center",
                borderRadius: "16px",
                padding: "24px 16px 16px 24px",
            }}
        >
            <Autocomplete
                options={manufacturers}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => <TextField {...params} label="Manufacturer" />}
                value={selectedManufacturer}
                onChange={(event, newValue) => setSelectedManufacturer(newValue)}
                fullWidth
                sx={{
                    mb: 3,
                    "& .MuiOutlinedInput-root": {
                        borderRadius: 2,
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            borderColor: "black",
                        },
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: "rgba(145, 158, 171, 0.8)",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                        borderRadius: 2,
                        borderColor: "rgba(145, 158, 171, 0.2)",
                    },
                    "& .MuiInputLabel-root": {
                        color: "rgba(145 158 171)",
                        "&.Mui-focused": {
                            color: "black",
                        },
                    },
                    "& .MuiInputBase-input::placeholder": {
                        color: "rgba(145, 158, 171, 0.8)",
                        opacity: 1,
                    },
                    "& .MuiInputBase-input:focus::placeholder": {
                        color: "rgba(145, 158, 171, 0.8)",
                    },
                }}
            />

            <TextField
                label="Part Number"
                name="partNumber"
                value={formData.partNumber}
                onChange={handleChange}
                fullWidth
                sx={{
                    mb: 3,
                    "& .MuiOutlinedInput-root": {
                        borderRadius: 2,
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            borderColor: "black",
                        },
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: "rgba(145, 158, 171, 0.8)",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                        borderRadius: 2,
                        borderColor: "rgba(145, 158, 171, 0.2)",
                    },
                    "& .MuiInputLabel-root": {
                        color: "rgba(145 158 171)",
                        "&.Mui-focused": {
                            color: "black",
                        },
                    },
                    "& .MuiInputBase-input::placeholder": {
                        color: "rgba(145, 158, 171, 0.8)",
                        opacity: 1,
                    },
                    "& .MuiInputBase-input:focus::placeholder": {
                        color: "rgba(145, 158, 171, 0.8)",
                    },
                }}
            />

            <Autocomplete
                options={categories}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => <TextField {...params} label="Category" />}
                value={selectedCategory}
                onChange={(event, newValue) => setSelectedCategory(newValue)}
                fullWidth
                sx={{
                    mb: 3,
                    "& .MuiOutlinedInput-root": {
                        borderRadius: 2,
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            borderColor: "black",
                        },
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: "rgba(145, 158, 171, 0.8)",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                        borderRadius: 2,
                        borderColor: "rgba(145, 158, 171, 0.2)",
                    },
                    "& .MuiInputLabel-root": {
                        color: "rgba(145 158 171)",
                        "&.Mui-focused": {
                            color: "black",
                        },
                    },
                    "& .MuiInputBase-input::placeholder": {
                        color: "rgba(145, 158, 171, 0.8)",
                        opacity: 1,
                    },
                    "& .MuiInputBase-input:focus::placeholder": {
                        color: "rgba(145, 158, 171, 0.8)",
                    },
                }}
            />

            <TextField
                label="Description"
                name="description"
                value={formData.description}
                onChange={handleChange}
                multiline
                rows={3}
                fullWidth
                sx={{
                    mb: 3,
                    "& .MuiOutlinedInput-root": {
                        borderRadius: 2,
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            borderColor: "black",
                        },
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: "rgba(145, 158, 171, 0.8)",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                        borderRadius: 2,
                        borderColor: "rgba(145, 158, 171, 0.2)",
                    },
                    "& .MuiInputLabel-root": {
                        color: "rgba(145 158 171)",
                        "&.Mui-focused": {
                            color: "black",
                        },
                    },
                    "& .MuiInputBase-input::placeholder": {
                        color: "rgba(145, 158, 171, 0.8)",
                        opacity: 1,
                    },
                    "& .MuiInputBase-input:focus::placeholder": {
                        color: "rgba(145, 158, 171, 0.8)",
                    },
                }}
            />

            
            <TextField
                label="Notes"
                name="notes"
                value={formData.notes}
                onChange={handleChange}
                multiline
                rows={3}
                fullWidth
                sx={{
                    mb: 3,
                    "& .MuiOutlinedInput-root": {
                        borderRadius: 2,
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            borderColor: "black",
                        },
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: "rgba(145, 158, 171, 0.8)",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                        borderRadius: 2,
                        borderColor: "rgba(145, 158, 171, 0.2)",
                    },
                    "& .MuiInputLabel-root": {
                        color: "rgba(145 158 171)",
                        "&.Mui-focused": {
                            color: "black",
                        },
                    },
                    "& .MuiInputBase-input::placeholder": {
                        color: "rgba(145, 158, 171, 0.8)",
                        opacity: 1,
                    },
                    "& .MuiInputBase-input:focus::placeholder": {
                        color: "rgba(145, 158, 171, 0.8)",
                    },
                }}
            />


            <Box sx={{ display: "flex", justifyContent: "end", alignItems: "end" }}>
                <Button
                    type="submit"
                    sx={{
                        mt: 3,
                        mb: 1,
                        borderRadius: "12px",
                        backgroundColor: "#F26E21",
                        color: "#fff",
                        textAlign: "center",
                        fontSize: "14px",
                        px: 2,
                        py: 2,
                        minWidth: 200,
                        height: "48px",
                        cursor: "pointer",
                        fontFamily: "'Poppins', sans-serif",
                        textTransform: "capitalize",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        "&:hover": {
                            backgroundColor: "#F26E21",
                            boxShadow: "none",
                        },
                        "&:active": {
                            backgroundColor: "#F26E21",
                            boxShadow: "none",
                        },
                        "&.Mui-focusVisible": {
                            backgroundColor: "#F26E21",
                            boxShadow: "none",
                        },
                    }}
                >
                    Submit
                </Button>
            </Box>

            {statusMessage && (
                <Box sx={{ color: statusMessage.type === 'success' ? 'green' : 'red', mt: 2 }}>
                    {statusMessage.text}
                </Box>
            )}
        </Box>
    );
};

export default EditPartInformation;